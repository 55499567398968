import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

const RegisterUserService = {
  getAllUsers: async () => {
    try { 
      const response = await axios.get(`${API_URL}/User`);
      return response.data.$values;
    } catch (error) {
      throw error;
    }
  },

  getUserById: async (id) => {
    try {
      const response = await axios.get(`${API_URL}/User/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  createUser: async (userData) => {
    try {
      const response = await axios.post(`${API_URL}/User/`, userData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateUser: async (id, userData) => {
    try {
      const response = await axios.put(`${API_URL}/User/UpdateUserAsync/${id}`, userData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  deleteUser: async (id) => {
    try {
      await axios.delete(`${API_URL}/User/${id}`);
    } catch (error) {
      throw error;
    }
  },

  getEmployessByRoleId: async (roleId) => {
    try {
      const response = await axios.get(`${API_URL}/User/GetEmployeeByRoleId/${roleId}`);
      return response.data.$values;
    } catch (error) {
      console.error(`Error fetching employees with role ID ${roleId}: `, error);
      throw error;
    }
  }, 


searchUsers: async (query) => {
  const response = await axios.get(`${API_URL}/trainees/search`, {
    params: { q: query }
  });
  return response.data;
},

};
export default RegisterUserService;
