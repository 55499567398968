import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css'; 
import ToastService from '../../Admin/services/ToastService';
import AuthService from '../../Admin/services/AuthService';
import ClientService from '../services/ClientService';
import { counter } from '@fortawesome/fontawesome-svg-core';
import { MdAddCircleOutline } from 'react-icons/md';



function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function AddClient() {

    const queryParam = useQuery();
    const navigate = useNavigate();
    const toast = useRef(null);
    const [Clients, setClients] = useState([]);
    const toastService = new ToastService(toast); 
    const [ClientId] = useState(null);
  
    const [form, setForm] = useState({ 
      clientname: "",
      streetaddress: "",  
      city: "",
      state: "",
      country:"",
      postalcode:"",
      isActive:true,
    });
  
    const [formError, setFormError] = useState([]);
  
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value });
        setFormError((prevErrors) => ({ ...prevErrors, [name]: '' })); 
      };

      async function getAll() {
        try {
          const clientsData = await ClientService.getAllCLients();
          setClients(clientsData);
        } catch (error) {
          console.log('Error fetching Clients: ', error);
        }
      }
    
      useEffect(() => {
        getAll();
      }, [ClientId]);


      function onClientSubmit() {

        const errors = {
          clientname: form.clientname.trim().length === 0,
          streetaddress: form.streetaddress.trim().length === 0,
          city: form.city.trim().length === 0,
          state: form.state.trim().length === 0,
          country: form.country.trim().length === 0,
        };
      
        const errorMessages = Object.keys(errors).reduce((acc, key) => {
          if (errors[key] === true) {
            return {
              ...acc,
              [key]: `Please enter ${key}!!!`,
            };
          } else if (typeof errors[key] === 'string') {
            return {
              ...acc,
              [key]: errors[key],
            };
          }
          return acc;
        }, {});
      
    
        setFormError(errorMessages);
      
        if (Object.keys(errorMessages).length === 0) {
         saveClient();
        }
      }
      
    
    function saveClient() {   
          const requestData = {
            clientname: form.clientname,
            streetaddress: form.streetaddress,
            city: form.city,
            state: form.state,
            country: form.country,
            postalcode: form.postalcode,
            isActive:true,
          };
          
          try {
         ClientService.createClient(requestData);        
            resetForm();
            navigate("/client")
          } catch (error) {
            if (error.response && error.response.status === 400) {
              const validationErrors = error.response.data.errors;
              console.log("Validation errors:", validationErrors);
              toastService.showError("Validation Error", "Please fill out all required fields");
            } else {
              console.log("Error saving client:", error);
              toastService.showError("Error", "Error saving client");
            }
          }
        }
    
      function resetForm() {
        setForm({
            clientname: "",
            streetaddress: "", 
            city: "",
            state: "",
            country:"",
            postalcode:""
        });
        setFormError([]);
      }


  return (
    <div>
         <Toast ref={toast} />

         
      <div className="row m-2 p-2">
            <div className="card text-center mx-auto">
                <div className="card-header bg-heading text-white">
                <MdAddCircleOutline />  New Client
                </div>

                <div className="card-body text-start">

                    <div className="form-group row">
                        <label className="col-lg-2 text-dark" htmlFor="clientName">
                             Name
                        </label>
                        <div className="col-lg-4">
                            <input 
                              type="text" 
                              name="clientname" 
                              id="clientName" 
                              className={`form-control ${formError.clientname ? 'is-invalid' : ''}`}
                              placeholder="Client Name" 
                              onChange={changeHandler} 
                              value={form.clientname} 
                              />
                            {formError.clientname && <div className="invalid-feedback">{formError.clientname}</div>}   
                            <p></p> 
                        </div>
                        <label className="col-lg-2 text-dark" htmlFor="streetaddress">
                        Street Address
                        </label>
                        <div className="col-lg-4">
                            <input 
                              type="text" 
                              name="streetaddress" 
                              id="streetaddress" 
                              className={`form-control ${formError.streetaddress ? 'is-invalid' : ''}`}
                              placeholder="Street Address" 
                              onChange={changeHandler} 
                              value={form.streetaddress} 
                              />
                        </div>
                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="city">
                            City
                        </label>
                        <div className="col-lg-4">
                            <input type="text" name="city" id="city" 
                            className={`form-control ${formError.city ? 'is-invalid' : ''}`}
                            placeholder="City" onChange={changeHandler}
                            value={form.city} 
                            />
                          {formError.city && <div className="invalid-feedback">{formError.city}</div>}   
                          <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="state">
                           State
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="text" 
                            name="state" 
                            id="state"
                            className={`form-control ${formError.state ? 'is-invalid' : ''}`}
                            placeholder="State" 
                            onChange={changeHandler}
                            value={form.state}                          
                            />
                           {formError.state && <div className="invalid-feedback">{formError.state}</div>}   
                           <p></p>
                        </div>
                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="country">
                            Country
                        </label>
                        <div className="col-lg-4">
                            <input type="text" name="country" id="country" 
                            className={`form-control ${formError.country ? 'is-invalid' : ''}`}
                            placeholder="Country" onChange={changeHandler}
                            value={form.country} 
                            />
                          {formError.country && <div className="invalid-feedback">{formError.country}</div>}   
                          <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="postalcode">
                           Postal Code 
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="number" 
                            name="postalcode" 
                            id="postalcode"
                            className={`form-control ${formError.postalcode ? 'is-invalid' : ''}`}
                            placeholder="Postal Code" 
                            onChange={changeHandler}
                          value={form.postalcode} 
                            />
                           {formError.postalcode && <div className="invalid-feedback">{formError.postalcode}</div>}   
                           <p></p>
                        </div>
                    </div>

            

                </div>
                
                <div className="card-footer text-muted bg-light">
                    <button className="btn btn-save btn-dark text-white" type="submit" onClick={onClientSubmit}>
                    <i className="fas fa-save"></i> Submit
                    </button>
                </div>
            </div>
        </div>

    </div>
  )
}

export default AddClient