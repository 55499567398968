import { Toast } from 'primereact/toast';
import React, { useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ToastService from '../../../Admin/services/ToastService';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function IntegrationSettings() {

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('');  
  const toast = useRef(null);
  const toastService = new ToastService(toast); 
  


  return (
    <div>

      <Toast ref={toast} />

      <h2>Integration Settings</h2> 

      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a className={`nav-link ${activeTab === 'facebook' ? 'active' : ''}`} onClick={() => setActiveTab('facebook')}>Facebook</a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${activeTab === '99acres' ? 'active' : ''}`} onClick={() => setActiveTab('99acres')}>99 Acres</a>
        </li>
      </ul>
      
      {activeTab === 'facebook' && (
        <h2>Facebook</h2>
      )}

      {activeTab === '99acres' && (
       <h2>99Acres Leads </h2>

      )}
     
    </div>
  )
}

export default IntegrationSettings