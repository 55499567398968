import React, { useState, useRef, useEffect } from 'react';
import PrivacyPolicyService from '../../modules/Admin/services/PrivacyPolicyService';
import ToastService from '../../modules/Admin/services/ToastService';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import AuthService from '../../modules/Admin/services/AuthService';

function Privacy() {
    const [Policy, setPolicy] = useState([]);
    const [policyId, setPolicyId] = useState('');
    const toast = useRef(null);
    const toastService = new ToastService(toast); 
    const navigate = useNavigate();
    const userRole = AuthService.getLoggedInRoleId(); 

    const [form, setForm] = useState({
        privacypolicytext: "",
        createddate: "", 
      });

    const [formError, setFormError] = useState([]);

    async function fetchPolicy() {
        try {
          const policy = await PrivacyPolicyService.getAllPolicies();
          setPolicy(policy);
        } catch (error) {
          console.error('Error fetching policy:', error);
          toastService.showError("Error", "Failed to fetching Policies");
        }
      };

      useEffect(() => {
        fetchPolicy();
      });


      function savePolicy() {   
        const requestData = {
        privacypolicytext: form.privacypolicytext,
        createddate: form.createddate, 
        };
    
        try {
          const response = PrivacyPolicyService.createPolicy(requestData);        
          resetForm();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            toastService.showError("Validation Error", "Please fill out all required fields");
          } else {
            toastService.showError("Error", "Error saving Policy");
          }
        }
      }

   async function updatePolicy() {

      const formData=new FormData();
      formData.append("privacypolicytext",form.privacypolicytext);
      formData.append("createddate",form.createddate);
      formData.append("id",policyId);

        try {
          await PrivacyPolicyService.updatePolicy(policyId, formData); 
          resetForm();
          toastService.showSuccess('Success', 'Policy updated successfully');
    
        } catch (error) {
          console.error("Error updating Policy:", error);
          toastService.showError('Error', 'Failed to update Policy');
        }
      };

      function resetForm() {
        setForm({
            privacypolicytext: "",
            createddate: "", 
        });
        setFormError({
            privacypolicytext: "",
            createddate: "", 
        });
      }

      const Role = {
        SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
        ADMIN: { roleId: 2, roleName: 'Admin' },
        MANAGER: { roleId: 3, roleName: 'Manager' },
        EMPLOYEE: { roleId: 4, roleName: 'Employee' },
      };

      function onPolicySubmit(){
          policyId ? updatePolicy() : savePolicy();
      }


      const handleInputChange = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      };
    
      const renderPolicyInParagraph = () => {
        return Policy.map((p) => (
          <p key={p.id}>
            <p>{p.privacyPolicyText}</p><br />
            <p><small>{new Date(p.createdDate).toLocaleString()}</small>  

            {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
            <button className="btn "
                    onClick={()=>{
                        setForm({...form, privacypolicytext:p.privacyPolicyText, createddate:p.createdDate});
                        setPolicyId(p.id);
                    }}>
              <i className="pi pi-pencil" style={{ fontSize: '0.8rem' }}></i>
            </button>
            )}

            </p><br/>
           

          </p>
        ));
      };

    return (
        <div>

        <Toast ref={toast} />

        <div className="container mt-4 bg-light">
              <div className="row">
                  {/* <div className="col-md-8 offset-md-2"> */}
                  <div className="col-12">
                      <div className="text-center mb-4">
                          <h2>Dream Town Builders and Promoters Privacy Policy</h2>
                      </div>
                      <p>
                      Dream Town Builders and Promoters ("we" or "us") respect the privacy of our users ("you"). 
                      This Privacy Policy describes the types of information we collect from and about you when you visit our website  
                      <a href="https://dreamtownbuilder.com/" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}> https://dreamtownbuilder.com/ </a> 
                      and how we use that information.
                      </p>
                    
                    <h5> Information We Collect</h5>
                        <p> We collect the following types of information from and about you:</p>
                          <li>Personal Information: This may include your name, email address, phone number, 
                            and mailing address. We only collect this information when you voluntarily provide it to us, 
                            such as when you contact us through the Website or request information about our properties.</li>

                          <li>Non-Personal Information: This may include your IP address, browser type, 
                            operating system, the referring website, the pages you visit on our website, and the time and duration of your visits. 
                            This information is collected automatically when you visit our website.</li>
                            <p></p>

                            <h5> How We Use Your Information</h5>
                        <p> We use the information we collect from and about you for the following purposes: </p>
                          <li>To respond to your inquiries and requests.</li>
                          <li>To send you information about our properties and services.</li>
                          <li>To improve our website and your experience using it.</li>
                          <li>To comply with the law.</li>
                          <p></p>

                          <h5> Sharing Your Information</h5>
                        <p> We will not share your Personal Information with any third party without your consent, except as follows:   </p>
                          <li>To service providers that we use to operate our business, such as website hosting providers and email marketing providers. 
                            These service providers are only authorized to use your Personal Information to provide the services we have requested.</li>
                          <li>To comply with the law or to respond to a subpoena or court order.</li>
                          <li>To protect the rights and safety of ourselves, our users, or others.</li>
                          <p></p>

                          <h5> Your Choices </h5>
                        <p>You have the following choices regarding your Personal Information: </p>
                          <li>You can opt out of receiving marketing emails from us by clicking on the "unsubscribe" link in any marketing email we send you.  </li>
                          <li>You can request to access, update, or delete your Personal Information by contacting us at:  <a href="mailto:info.dreamtownbuilders@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}> 
                             info.dreamtownbuilders@gmail.com. </a>   </li>
                          
                          <p></p>

                          <h5>Data Security</h5>
                            <p>
                                  We take reasonable steps to protect your Personal Information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure. 
                                  We cannot guarantee the security of your Personal Information.
                            </p>
                            <p></p>

                            <h5>Children's Privacy</h5>
                            <p>
                            Our website is not directed to children under the age of 13. 
                            We do not knowingly collect Personal Information from children under the age of 13. 
                            If you are a parent or guardian and you believe that your child has provided us with Personal Information, 
                            please contact us at <a href="mailto:info.dreamtownbuilders@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}> 
                            info.dreamtownbuilders@gmail.com</a>.
                            We will delete any Personal Information we have collected from children under the age of 13.  
                            </p>
                            <p></p>
                          
                            <h5>Changes to This Privacy Policy</h5>
                            <p>
                            We may update this Privacy Policy from time to time.
                            We will post any changes to this Privacy Policy on our Website. 
                            We encourage you to review this Privacy Policy periodically for the latest information on our privacy practices.    
                            </p>
                            <p></p>
                          
                            <h5>Contact Us</h5>
                            <p>
                            If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info.dreamtownbuilders@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}> 
                            info.dreamtownbuilders@gmail.com</a>.   
                            </p>
                            <p></p>
                          
                  </div>
              </div>
          </div>

       <br/>

        {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
          
          <div className='form-group row'>
            <hr/>
            <h5>  {policyId?"Edit Policy":"New Policy"}</h5> 
            <label className="col-1 text-dark col-form-label">Privacy Policy</label>
            <div className="col-lg-3">
            <textarea
            rows={3}
            className="form-control"
            name="privacypolicytext"
            value={form.privacypolicytext}
            onChange={handleInputChange}
            placeholder="Enter privacy policy..." 
          />
          </div>

          <label className="col-lg-1 text-dark col-form-label">Created Date</label>
          <div className="col-lg-2">
          <input
            type="datetime-local"
            name="createddate"
            value={form.createddate}
            onChange={handleInputChange}
            placeholder="Date and time"
          /> 
          </div>

          <div className="col-lg-1">
          {policyId?(
          <button onClick={()=>{
            onPolicySubmit();
          }}
          className='btn btn-info'>
            Update
          </button>
        ):(
          <button onClick={()=>{
            onPolicySubmit();
          }} 
          className="btn btn-save btn-dark text-white">
            <i className="fas fa-save"></i>
            Save
          </button>
        )
      }
          </div>

          {/* <div className='col-lg-4'> */}
          <p>{renderPolicyInParagraph()}</p>
          {/* </div> */}

          </div>

        )}

    </div>
  );
};

export default Privacy;
