import axios from 'axios';
import AuthService from './AuthService';
import { Navigate } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  if (AuthService.isTokenExpired(token)) {
    AuthService.logout(); 
    <Navigate to="/login" /> 
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
 


const TermsAndConditionsService = {
  getAllTerms: async () => {
    try {
      const response = await axios.get(`${API_URL}/TermsAndConditions/GetAllTermsAndConditions`);
      return response.data.$values;
    } catch (error) { 
      console.error('Error fetching terms and conditions:', error);
      throw error;
    }
  },

  getTermsById: async (id) => { 
    try {
      const response = await axios.get(`${API_URL}/TermsAndConditions/GetTermsAndConditionsById/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching terms and conditions with ID ${id}:`, error);
      throw error;
    } 
  },


  createTerms: async (terms) => {
    try {
      const response = await axios.post(`${API_URL}/TermsAndConditions/CreateTermsAndConditions`, terms);
      return response.data;
    } catch (error) {
      console.error('Error creating terms and conditions:', error);
      throw error;
    }
  },

  updateTerms: async (id, terms) => {
    try {
      const response = await axios.put(`${API_URL}/TermsAndConditions/UpdateTermsAndConditions/${id}`, terms);
      return response.data;
    } catch (error) {
      console.error(`Error updating terms and conditions with ID ${id}:`, error);
      throw error;
    }
  }, 

//   deleteLead: async (id) => {
//     try {
//       const response = await axios.delete(`${API_URL}/Lead/${id}`);
//       return response.data;
//     } catch (error) {
//       console.error(`Error deleting lead with ID ${id}:`, error); 
//       throw error;
//     }
//   },


};


export default TermsAndConditionsService;
