import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const CallsLineGraph = ({
  totalCallsCount,
  phoneNotPickedCount,
  invalidNumberCount,
  contactAfterSomeTimeCount,
  callBackLaterCount,
}) => {
  const data = {
    labels: [
      'Total Calls',
      'Phone Not Picked',
      'Invalid Numbers',
      'Contact After SomeTime',
      'Call Back Later',
    ],
    datasets: [
      {
        label: 'Total Calls',
        data: [totalCallsCount, 0, 0, 0, 0],
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.5)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 3,
        tension: 0.3,
        pointBackgroundColor: 'rgba(54, 162, 235, 1)',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
      {
        label: 'Phone Not Picked',
        data: [0, phoneNotPickedCount, 0, 0, 0],
        fill: false,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 3,
        tension: 0.3,
        pointBackgroundColor: 'rgba(255, 99, 132, 1)',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
      {
        label: 'Invalid Numbers',
        data: [0, 0, invalidNumberCount, 0, 0],
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 3,
        tension: 0.3,
        pointBackgroundColor: 'rgba(75, 192, 192, 1)',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
      {
        label: 'Contact After SomeTime',
        data: [0, 0, 0, contactAfterSomeTimeCount, 0],
        fill: false,
        backgroundColor: 'rgba(153, 102, 255, 0.5)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 3,
        tension: 0.3,
        pointBackgroundColor: 'rgba(153, 102, 255, 1)',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
      {
        label: 'Call Back Later',
        data: [0, 0, 0, 0, callBackLaterCount],
        fill: false,
        backgroundColor: 'rgba(255, 159, 64, 0.5)',
        borderColor: 'rgba(255, 159, 64, 1)',
        borderWidth: 3,
        tension: 0.3,
        pointBackgroundColor: 'rgba(255, 159, 64, 1)',
        pointBorderColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 14,
            weight: 'bold',
            family: 'Arial, sans-serif',
          },
        },
      },
      title: {
        display: true,
        text: 'Calls Record',
        font: {
          size: 18,
          weight: 'bold',
          family: 'Arial, sans-serif',
        },
        padding: {
          top: 10,
          bottom: 30,
        },
      },
      tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleFont: {
          size: 16,
        },
        bodyFont: {
          size: 14,
        },
        padding: 10,
        cornerRadius: 5,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            family: 'Arial, sans-serif',
          },
        },
      },
      y: {
        type: 'linear',
        beginAtZero: true,
        min: 0, // Ensures the y-axis starts at 0
        max: 200, // Sets the maximum value of the y-axis to 4
        grid: {
          color: 'rgba(200, 200, 200, 0.2)',
        },
        ticks: {
          font: {
            size: 12,
            family: 'Arial, sans-serif',
          },
        },
      },
    },
  };

  return (
    <div style={{ height: '500px', width: '800px' }}>
      <Line data={data} options={options} />
    </div>
  );
};

export default CallsLineGraph;
