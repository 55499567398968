import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import AuthService from '../../modules/Admin/services/AuthService';
import NotificationService from '../../modules/Admin/services/NotificationService';
import LeadService from '../../modules/Admin/services/LeadService';
import profilePic from '../../Images/DreamTown.png'; 
import { Badge } from 'primereact/badge';
import axios from 'axios';
import { FaBuilding, FaSignOutAlt, FaTrash, FaUser, FaUserAlt } from 'react-icons/fa';

function Navbar() {
  const [isLoggedIn, setIsLoggedIn] = useState(AuthService.isAuthenticated());
  const [searchTerm, setSearchTerm] = useState('');
  const [showNotifications, setShowNotifications] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const toast = useRef(null);
  const [currentTime, setCurrentTime] = useState('');
  const [greeting, setGreeting] = useState('');
  const [userId, setUserId] = useState(AuthService.getCurrentUser().UserId);
  const [lastLoginTime, setLastLoginTime] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [assignedNotifications, setAssignedNotifications] = useState([]);


  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if (currentUser) {
      setUserId(currentUser.UserId);
    } else {
      console.error('User is not logged in.');
      navigate('/login');
    }
  }, []);


  useEffect(() => {
   async function fetchNotificationsByDate()  {
      try {
        const response = await NotificationService.getNotificationsByDateAsync(userId);
        setAssignedNotifications(response);
        console.log(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setLoading(false);
      }
    };
    fetchNotificationsByDate();
  }, [userId]);
  
  
  useEffect(() => {
    async function getNotifications () {
      try {
        const response = await NotificationService.getNotificationsByDate(userId);
        setAssignedNotifications(response);
        setTotalCount(response.totalCount || 0);
        setAssignedNotifications(response.leadAssignedNotifications)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setLoading(false);
      }
    };
    getNotifications();
  }, [userId]);

  

  useEffect(() => {
    const updateTimeAndGreeting = () => {
      const date = new Date();
      let hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const ampm = hours >= 12 ? 'PM' : 'AM';

  
      if (hours < 12) {
        setGreeting('Good Morning');
      } else if (hours < 18) {
        setGreeting('Good Afternoon');
      } else {
        setGreeting('Good Evening');
      }

      hours = hours % 12;
      hours = hours ? hours : 12; 
      const minutesFormatted = minutes < 10 ? '0' + minutes : minutes;
      const secondsFormatted = seconds < 10 ? '0' + seconds : seconds;

      setCurrentTime(`${hours}:${minutesFormatted}:${secondsFormatted} ${ampm}`);
    };

    updateTimeAndGreeting();
    const intervalId = setInterval(updateTimeAndGreeting, 1000); 

    return () => clearInterval(intervalId); 
  }, []);


  useEffect(() => {
    const fetchLastLoginTime = async () => {
      try {
        const lastLogin = await AuthService.getLastLoginTime(userId);
        if (lastLogin) {
          const formattedTime = new Date(lastLogin).toLocaleString();
          setLastLoginTime(formattedTime);
        }
      } catch (error) {
        console.error('Error fetching last login time:', error);
      }
    };

    if (userId) {
      fetchLastLoginTime();
    }
  }, [userId]);

  // const handleClearNotification = async (id) => {
  //   setLoading(true);
  //   try {
  //   
  //     const response = await NotificationService.clearLeadAssignedNotificationById(id);
  //     setSuccess('Lead assigned notification cleared successfully');
  //     setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
  //        setNotificationCount(prevCount => prevCount - 1);
  //   } catch (error) {
  //     
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem('hasShownPopup');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('lastActivityTime');
    toast.current.show({ 
      severity: 'success', 
      summary: 'Logged Out', 
      detail: 'User Logged Out successfully!!', 
      className: 'custom-toast-delete'
    });
    delete axios.defaults.headers.common["Authorization"];
    setIsLoggedIn(false);

    setTimeout(() => {
      navigate("/login");
    }, 700); 
  };

  const handleAccountClick = () => {
    const userId = AuthService.getCurrentUser().UserId; 
    navigate(`/edituser/${userId}`);
  };

   function handleNotificationClick () {
    setShowNotifications(!showNotifications);
  };

  const handleSearch = async () => {
    if (searchTerm.trim() !== '') {
      try {
        const leads = await LeadService.searchLeads(searchTerm);
        navigate(`/search?query=${encodeURIComponent(searchTerm)}`, { state: { leads } });
      } catch (error) {
        console.error('Error performing search:', error);
        toast.current.show({ severity: 'error', summary: 'Search Error', detail: 'An error occurred while searching. Please try again later.', life: 3000 });
      }
    }
    setSearchTerm('');
  };


  const refreshNotifications = async () => {
    setLoading(true); // Show loader while fetching
    try {
      const response = await NotificationService.getNotificationsByDate(userId);
      setAssignedNotifications(response.leadAssignedNotifications);
      setTotalCount(response.totalCount || 0);
    } catch (error) {
      console.error('Error refreshing notifications:', error);
    } finally {
      setLoading(false); // Hide loader once fetching is done
    }
  };



function formatTimeElapsed(timestamp) {
  const timeElapsed = Math.floor((new Date() - new Date(timestamp)) / 1000);
  if (timeElapsed < 60) {
    return `${timeElapsed} seconds ago`;
  } else if (timeElapsed < 3600) {
    return `${Math.floor(timeElapsed / 60)} minutes ago`;
  } else if (timeElapsed < 86400) {
    return `${Math.floor(timeElapsed / 3600)} hours ago`;
  } else {
    return `${Math.floor(timeElapsed / 86400)} days ago`;
  }
}
// const clearNotification = (notificationId) => {
//     setNotifications(notifications.filter((notification) => notification.date !== notificationId));
//   };


  // const clearNotification = async (notificationId) => {
  //   try {
  //     await NotificationService.clearNotification(notificationId);
  //     setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== notificationId));
  //     setNotificationCount(prevCount => prevCount - 1);
  //     toast.current.show({ severity: 'success', summary: 'Notification Cleared', detail: 'Notification has been cleared.', life: 3000 });
  //   } catch (error) {
  //     console.error('Error clearing notification:', error);
  //     toast.current.show({ severity: 'error', summary: 'Clear Error', detail: 'An error occurred while clearing the notification. Please try again later.', life: 3000 });
  //   }
  // };
      
  return (
    <div>
      <Toast ref={toast} />
      <nav className="navbar navbar-expand-lg navbar-dark navBarBackground ">
        <div className="container-fluid ">
        <h5 className='text-dark justify-content-start'>
            {greeting}, Dream Town Builders & Promoters <FaBuilding /> , {currentTime}
          </h5>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" 
                  data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
                  aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
            
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
             <ul>
              <li className="nav-item">
                <span className="navbar-text text-dark">Last Login: {lastLoginTime || 'Fetching...'}</span>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto">
              <input
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                    setSearchTerm('');
                  }
                }}
                placeholder="Search leads"
                aria-label="Search"
                className="form-control me-2"
              />
              <button className='btn btn-search text-white' onClick={handleSearch}>Search</button>
            </ul>
            <ul></ul>

            <ul className="navbar-nav">
              <button className='btn btn-sm' onClick={handleNotificationClick}>
                <i className="pi pi-bell p-overlay-badge" style={{ fontSize: '1.5rem' }}>
                  <Badge value={totalCount}></Badge>
                </i>
              </button>
            </ul>

            <ul className="navbar-nav profile-menu"> 
              <li className="nav-item dropdown">
              <button className="nav-link dropdown-toggle" 
                      id="navbarDropdown" 
                      data-bs-toggle="dropdown" 
                      aria-expanded="false" 
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: 0,
                        font: 'inherit',
                        color: 'inherit',
                        cursor: 'pointer'
                      }}>
                <div className="profile-pic">
                  <img src={profilePic} alt="User's Profile" style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    borderRadius: '50%'
                  }} />
                </div>
              </button>

                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <button className="dropdown-item" onClick={handleAccountClick} style={{
                            background: 'none',
                            border: 'none',
                            padding: 5,
                            margin:7,
                            font: 'inherit',
                            color: 'inherit',
                            cursor: 'pointer'
                          }}>
                            <FaUser /> Your Profile
                          </button>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  <li>
                    {isLoggedIn ? (
                      <button className="dropdown-item" onClick={handleLogout} style={{
                              background: 'none',
                              border: 'none',
                              padding: 5,
                              margin:7,
                              font: 'inherit',
                              color: 'inherit',
                              cursor: 'pointer'
                            }}>
                              <FaSignOutAlt /> LogOut
                            </button>
                    ) : (
                      <button className="dropdown-item" onClick={() => navigate('/login')}
                      style={{
                        background: 'none',
                        border: 'none',
                        padding: 5,
                        margin:7,
                        font: 'inherit',
                        color: 'inherit',
                        cursor: 'pointer'
                      }}
                      >
                      <FaUserAlt />   Login
                      </button>
                    )}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      
  
      <div className={`notification-bar ${showNotifications ? 'open' : ''}`}>
        <div className="notification-header">
          <h5>
            <i className="pi pi-bell" style={{ marginRight: '8px' }}></i> 
            Notifications
          </h5>
          <div className="notification-actions">
            <button className="close-btn" onClick={() => setShowNotifications(false)}>&times;</button>
          </div>
        </div>

        <div className="notification-body">
          {
            assignedNotifications.length > 0 ? (
              assignedNotifications.map((notification, index) => (
                <div key={notification.date} className="notification-item">
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{notification.message}</span>
                    <br/>
                    <span className="text-muted">
                      {formatTimeElapsed(notification.date)}
                    </span>
                  </div>
                  {index < assignedNotifications.length - 1 && <hr />}
                </div>
              ))
            ) : (
              <b>No new Notifications</b>
            )
          }
        </div>
      </div>


    </div>
  );
}

export default Navbar;
