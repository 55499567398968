import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import $ from 'jquery';
import 'datatables.net';
import ToastService from '../../services/ToastService';
import LeadsAssignedService from '../../services/LeadsAssignedService';
import RegisterUserService from '../../services/RegisterUserService';
import AuthService from '../../services/AuthService';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function PendingFollowUps() {

const [pendingFollowups, setPendingFollowups] = useState([]);
const [loading, setLoading] = useState(true);
const [error, setError] = useState(null);
const toast = useRef(null);
const toastService = new ToastService(toast);
const [selectedRows, setSelectedRows] = useState([]);
const [selectAll, setSelectAll] = useState(false);  
const navigate = useNavigate(); 
const [searchTerm] = useState('');
const tableRef = useRef(null);
const [employees, setselectedUser] =  useState([]);
const queryParam = useQuery();
const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);
const [LeadsAssigned, setLeadsAssigned] = useState([]);


useEffect(() => {
    const fetchPendingFollowups = async () => {
    try {
        const userId= UserId;
        const data = await LeadsAssignedService.getPendingFollowups(userId);
        
            const sortedData = data.sort((a, b) => {
              const dateA = new Date(a.nextUpdate);
              const dateB = new Date(b.nextUpdate);
              return dateB - dateA;
          });

          setPendingFollowups(sortedData);
        } catch (error) {
        setError('Failed to fetch pending follow-ups');
        } finally {
        setLoading(false);
        }
    };

    const fetchEmployee = async()=> {
        try {
          const users = await RegisterUserService.getEmployessByRoleId(4);
          setselectedUser(users);
        } catch (error) { 
          console.error('Error fetching users:', error);  
          toastService.showError("Error", "Failed to fetch users data");
        }
      };

    fetchPendingFollowups();
    fetchEmployee();
    setTimeout(() => {
        $('#tableData').DataTable();
        }, 1000);
    }, [UserId]);


    useEffect(() => {
      if (Notification.permission === 'default') {
        Notification.requestPermission();
      }
    }, []);


    const sourceTypes = [
      { id: 1, name: "Facebook" },
      { id: 2, name: "Instagram" },
      { id: 3, name: "Reference" },
    ];
  
  
    const Role = {
      SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
      ADMIN: { roleId: 2, roleName: 'Admin' },
      MANAGER: { roleId: 3, roleName: 'Manager' },
      EMPLOYEE: { roleId: 4, roleName: 'Employee' },
    };
  
    const PropertyType= [
      { id: 1, name: "1BHK" },
      { id: 2, name: "2BHK" },
      { id: 3, name: "3BHK" },
      { id: 4, name: "4BHK" },
      { id: 5, name: "5BHK" },
      { id: 6, name: "Plot" },
      { id: 7, name: "Kothi" },
      { id: 8, name: "Other" },
    ];
  
    const LeadStatus= [
      { id: 1, name: "HotClient" },
      { id: 2, name: "Interested" },
      { id: 3, name: "NotInterested" },
      { id: 4, name: "PhoneNotPicked" },
      { id: 5, name: "InvalidNumber" },
      { id: 6, name: "ContactAfterSomeTime" },
      { id: 7, name: "CallBackLater" },
      { id: 8, name: "UpcomingVisit" },
      { id: 9, name: "Complete" },
      { id: 10, name: "Visited" },
    ];
  
  //   const setReminder = (nextUpdate, followUpTime, leadName, leadCode, leadContactNumber) => {

  //     const [hours, minutes] = followUpTime.split(':');
  //     const followUpDateTime = new Date(`${nextUpdate}`);
  
  //     const currentTime = new Date();
  //     const timeDifference = followUpDateTime - currentTime;

  //     if (timeDifference > 0) {
  //         setTimeout(() => {
  //             if (Notification.permission === 'granted') {
  //                 new Notification(`Follow up Reminder`, {
  //                     body: `It's time to follow up with ${leadName}, ${leadCode}, ${leadContactNumber}!`,
  //                 });
  //             } 
  //             // else {
  //             //     alert(`It's time to follow up with ${leadName}!`);
  //             // }
  //         }, timeDifference);
  //     }
  // };

    const filteredpendingFollowups = (pendingFollowups || []).filter((pendingfollowups) => {
      const leadStatus = LeadStatus.find((leadstatus) => leadstatus.id === pendingfollowups.leadStatusId);
      const leadStatusName = leadStatus ? leadStatus.name : "Unknown";
  
      if (leadStatusName === 'NotInterested' || leadStatusName === 'InvalidNumber') {
          return false; 
      }

      return (
        pendingfollowups.conversationComment &&
        pendingfollowups.conversationComment.toLowerCase().includes(searchTerm.toLowerCase())
      );
  });
  

  function getStatusClass(leadStatusName) {
    switch (leadStatusName) {
      case 'Interested':
        return 'table-success'; 
      case 'HotClient':
        return 'table-danger'; 
      case 'NotInterested':
        return 'table-info';   
      case 'CallBackLater':
        return 'table-warning'; 
      case 'InvalidNumber':
        return 'table-primary';
      case 'Complete':
        return 'table-primary';
        case 'UpcomingVisit':
          return 'table-warning';
      default:
        return ''; 
    }
  }


  const renderPendingFollowups = () => {
    if (!Array.isArray(filteredpendingFollowups)) return null; 

    return filteredpendingFollowups.map((pending) => {

        const lead = pending.lead;

        const sourceType = lead ? sourceTypes.find((source) => source.id === lead.sourceTypeId) : null;
        const sourceTypeName = sourceType ? sourceType.name : "Unknown";
    
        const leadStatus = LeadStatus.find((leadstatus) => leadstatus.id === pending.leadStatusId);
        const leadStatusName = leadStatus ? leadStatus.name : "Unknown";
  
        const property = PropertyType.find((propertytype) => propertytype.id === pending.propertyTypeId);
        const propertyTypeName = property ? property.name : "Unknown";
  
        const employee = employees.find((emp) => emp.id === pending.userId);
        const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : 'InActive Employee';


        // setReminder(pending.nextUpdate, pending.followUpTime, lead ? lead.firstName : 'Unknown Lead', lead ? lead.leadCode : 'Unknown Lead', lead ? lead.contactNumber : 'Unknown Lead');
     
      return (
        <tr key={pending.id}>
      
        <td>{pending.conversationComment}</td>
        <td>{lead ? `${lead.leadCode}` : 'Unknown Lead'}</td>
        <td>{lead ? `${lead.firstName}` : 'Unknown Lead'}</td>
        {/* <td>{lead ? `${lead.email}` : 'Unknown Lead'}</td> */}
        <td>{lead ? `${lead.contactNumber}` : 'Unknown Lead'}</td>
        <td>{lead ? `${lead.source}` : 'Unknown Lead'}</td>
        {/* <td>{sourceTypeName}</td> */}

        <td className={getStatusClass(leadStatusName)}> 
              {leadStatusName}
        </td>
        <td>{propertyTypeName}</td> 
        <td>{lead ? `${lead.property}` : 'Unknown Lead'}</td>
        <td>
          {new Date(pending.leadAssignedDate).toLocaleDateString()}
        </td> 
        <td>{employeeName}</td>
        <td>
              {new Date(pending.followUpDate).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
              })}
          </td>

          <td>
              {new Date(pending.nextUpdate).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
              })}
          </td>
        <td>
          <button
            className="btn btn-outline-dark btn-sm"
            onClick={() => navigate(`/editleadassigned/${pending.id}`)}
          >
            <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
          </button>
        </td>
      </tr>
      );
    });
  };
  

  return (
    <div>
      <Toast ref={toast} />
      <h1>Pending FollowUps</h1>

      <div className="p-2 m-2">

      <table id="tableData" ref={tableRef} className="table display">
          <thead>
            <tr>             
              <th>Conversation Comments</th>
              <th>Lead Code</th>
              <th>Lead Name</th>
              {/* <th>Email</th> */}
              <th>Contact Number</th>
              <th>Source</th>
              <th>Lead Status</th>      
              <th>Property Type</th>
              <th>Property</th>
              <th>Lead Assigned Date</th> 
              <th>Employee</th>             
              <th>Follow Up</th>
              <th>Next FollowUp</th>     
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{renderPendingFollowups()}</tbody>
        </table>
      </div>
    </div>
  );
}

export default PendingFollowUps;