import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import LeadsAssignedService from '../../services/LeadsAssignedService';
import { Toast } from 'primereact/toast';
import AuthService from '../../services/AuthService';
import LeadService from '../../services/LeadService';
import RegisterUserService from '../../services/RegisterUserService';
import ToastService from '../../services/ToastService';
import { MdRepeat } from 'react-icons/md';


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function FilterDataDashboardList() {
  const { employeeId, fromDateParam, toDateParam } = useParams();
  const [LeadsAssigned, setLeadsAssigned] = useState([]);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(''); 
  const toast = useRef(null);
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const queryParam = useQuery();
  const fromDate = new Date(fromDateParam);
  const toDate = new Date(toDateParam);
  const toastService = new ToastService(toast);
  const [userId, setUserId] = useState(AuthService.getCurrentUser().UserId);
  const [leadStatus, setLeadStatus]= useState([]);
  const [selectedStatus, setSelectedStatus] = useState(''); 
  const [globalSearchTerm, setGlobalSearchTerm] = useState('');
  const userRole = AuthService.getLoggedInRoleId(); 
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false); 
  const [selectedValue, setSelectedValue] = useState('');
  const [isEmployeeSelectedError, setIsEmployeeSelectedError] = useState(false);


  useEffect(() => {
    async function fetchLeadsAssigned() {
      try {
  
        const leadsassignedData = await LeadsAssignedService.getAllLeadsAssigned(userId);
        const sortedLeadsAssigned = leadsassignedData.sort((a, b) => new Date(b.leadAssignedDate) - new Date(a.leadAssignedDate));
        setLeadsAssigned(sortedLeadsAssigned);
      } catch (error) {
        console.error('Error fetching leads assigned:', error);  
       // toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch leads assigned data' });
      }
    };

    fetchLeadsAssigned();
  }, [userId]); 
  

  const filteredLeadsAssigned = LeadsAssigned.filter((leadassigned) => {
    const statusMatches = selectedStatus ? leadassigned.leadStatusId === parseInt(selectedStatus) : true;

    const lead = leadassigned.lead;
    const leadNameMatches = lead ? 
        `${lead.firstName}`.toLowerCase().includes(globalSearchTerm.toLowerCase()) : false;
    const phoneNumberMatches = lead ? 
        lead.contactNumber.toLowerCase().includes(globalSearchTerm.toLowerCase()) : false;
    const emailMatches = lead ? 
        lead.email.toLowerCase().includes(globalSearchTerm.toLowerCase()) : false;

    const searchMatches = leadassigned.conversationComment &&
        leadassigned.conversationComment.toLowerCase().includes(globalSearchTerm.toLowerCase()) ||
        leadNameMatches || phoneNumberMatches || emailMatches;
     
    const clearTime = (date) => {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
      };
      const leadDate = clearTime(new Date(leadassigned.leadAssignedDate));
      const fromDateClear = fromDate ? clearTime(new Date(fromDate)) : null;
      const toDateClear = toDate ? clearTime(new Date(toDate)) : null;
      const isWithinDateRange =  (!fromDateClear || leadDate >= fromDateClear) &&  (!toDateClear || leadDate <= toDateClear);

    const isEmployeeMatch = !employeeId || userId == employeeId || leadassigned.userId == employeeId;

    return statusMatches && searchMatches && isWithinDateRange && isEmployeeMatch;
  }); 



const handleStatusChange = (event) => {
  setSelectedStatus(event.target.value); 
};

const handleChange = (event) => {
  setSelectedValue(event);
  if (event) {
    setIsEmployeeSelectedError(false); 
  }
};


  useEffect(() => {    
    const fetchLeadStatus =async()=>{
        try{
          const leadstatus = await LeadService.getAllLeadStatus();
          setLeadStatus(leadstatus);

        }catch(error){
          console.error('Error fetching lead status:', error);
        }
    }

    const fetchEmployee = async()=> {
      try {
        const users = await RegisterUserService.getEmployessByRoleId(4);
        setEmployees(users);
      } catch (error) { 
        console.error('Error fetching users:', error);  
        toastService.showError("Error", "Failed to fetch users data");
      }
    };

    fetchLeadStatus();
    fetchEmployee();

  }, [userId]);


  const LeadStatus = [
    { id: 1, name: "HotClient" },
    { id: 2, name: "Interested" },
    { id: 3, name: "NotInterested" },
    { id: 4, name: "PhoneNotPicked" },
    { id: 5, name: "InvalidNumber" },
    { id: 6, name: "ContactAfterSomeTime" },
    { id: 7, name: "CallBackLater" },
    { id: 8, name: "Upcoming Visit" },
    { id: 9, name: "Complete" },
    { id: 10, name: "Visited" },
  ];

  const PropertyType = [
    { id: 1, name: "1BHK" },
    { id: 2, name: "2BHK" },
    { id: 3, name: "3BHK" },
    { id: 4, name: "4BHK" },
    { id: 5, name: "5BHK" },
    { id: 6, name: "Plot" },
    { id: 7, name: "Kothi" },
    { id: 8, name: "Other" },
  ];

  const handleLeadStatusChange = (event) => {
    setSelectedLeadStatus(event.target.value);
  };


  function getStatusClass(leadStatusName) {
    switch (leadStatusName) {
      case 'Interested':
        return 'table-success'; 
      case 'HotClient':
        return 'table-danger'; 
      case 'NotInterested':
        return 'table-info';   
      case 'CallBackLater':
        return 'table-warning'; 
      case 'InvalidNumber':
        return 'table-primary';
      case 'Complete':
        return 'table-primary';
        case 'Visited':
          return 'table-info';
          case 'UpcomingVisit':
            return 'table-warning';
      default:
        return ''; 
    }
  }

  const sourceTypes = [
    { id: 1, name: "Facebook" },
    { id: 2, name: "Instagram" },
    { id: 3, name: "Reference" },
  ];

  useEffect(() => {
    if (Notification.permission === 'default') {
      Notification.requestPermission();
    }
  }, []);

//   const setReminder = (nextUpdate, followUpTime, leadName, leadCode, leadContactNumber) => {
  
//     const [hours, minutes] = followUpTime.split(':');
//     const followUpDateTime = new Date(`${nextUpdate}`);

//     const currentTime = new Date();
//     const timeDifference = followUpDateTime - currentTime;

//     // If time difference is positive, set a reminder
//     if (timeDifference > 0) {
//         setTimeout(() => {
//             if (Notification.permission === 'granted') {
//                 new Notification(`Follow up Reminder`, {
//                     body: `It's time to follow up with ${leadName}, ${leadCode}, ${leadContactNumber}!`,
//                 });
//               }
//             //  else {
//             //     alert(`It's time to follow up with ${leadName}!`);
//             // }
//         }, timeDifference);
//     }
// };


  const renderLeadsAssigned = () => {
    return filteredLeadsAssigned.map((leadassigned) => {

      const lead = leadassigned.lead;

      const sourceType = lead ? sourceTypes.find((source) => source.id === lead.sourceTypeId) : null;
      const sourceTypeName = sourceType ? sourceType.name : "Unknown";
  
      const leadStatus = LeadStatus.find((leadstatus) => leadstatus.id === leadassigned.leadStatusId);
      const leadStatusName = leadStatus ? leadStatus.name : "Unknown";

      const property = PropertyType.find((propertytype) => propertytype.id === leadassigned.propertyTypeId);
      const propertyTypeName = property ? property.name : "Unknown";

    
      const employee = employees.find((emp) => emp.id === leadassigned.userId);
      const employeeName = employee ? `${employee.firstName} ${employee.lastName}` : 'InActive Employee';
      
    //  setReminder(leadassigned.nextUpdate, leadassigned.followUpTime, lead ? lead.firstName : 'Unknown Lead', lead ? lead.leadCode : 'Unknown Lead', lead ? lead.contactNumber : 'Unknown Lead');

      return (
        <tr key={leadassigned.id}>

{(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
          <td>
          <input 
            type="checkbox" 
            checked={selectedRows.includes(leadassigned.leadId)}
            onChange={() => handleRowSelection(leadassigned.leadId)} />
          </td>
          )}
        
          <td>{lead ? `${lead.leadCode}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.firstName}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.email}` : 'Unknown Lead'}</td>
          <td>{lead ? `${lead.contactNumber}` : 'Unknown Lead'}</td>
          {/* <td>{sourceTypeName}</td> */}
          <td className={getStatusClass(leadStatusName)}> 
                {leadStatusName}
          </td>
          <td>{leadassigned.conversationComment}</td>
 
          <td>{propertyTypeName}</td> 
          <td>{lead ? `${lead.property}` : 'Unknown Lead'}</td> 
          <td>
            {new Date(leadassigned.leadAssignedDate).toLocaleDateString()}
          </td>
          <td>{employeeName}</td>
          <td>
              {new Date(leadassigned.followUpDate).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
              })}
          </td>

          <td>
              {new Date(leadassigned.nextUpdate).toLocaleString('en-US', {
                  hour: 'numeric',
                  minute: 'numeric',
                  second: 'numeric',
                  hour12: true,
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
              })}
          </td>
          <td>
            <button
              className="btn btn-outline-dark btn-sm"
              onClick={() => navigate(`/editleadassigned/${leadassigned.id}`)}
            >
              <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
            </button>
          </td>
        </tr>
      );
    });
  };

  
  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedRows(LeadsAssigned.map(lead => lead.leadId)); 
    } else {
      setSelectedRows([]); 
    }
  };

  const handleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };


  const ReAssignLead = async()=>{
    if (!selectedValue) {
      toastService.showWarning("Warning", "Please select an employee to re-assign the lead");
      return;
    }
    if (selectedRows.length === 0) {
      toastService.showWarning("Warning", "Please select at least one lead to re-assign");
      return;
    }
    
    try {
      await LeadsAssignedService.ReAssignedLeads(selectedValue, selectedRows);
        toastService.showSuccess("Success", "Lead Re-Assigned successfully");
    } catch (error) {
      toastService.showError("Error", "Failed to Assign lead");
    }
  }

  const Role = {
    SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };

  

  return (
    <div>
      <Toast ref={toast} />
      <h3>Filtered Assigned Leads</h3>
      

      
      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

<div className="form-group row">
        <div className="col-lg-6">
          <select
            name="userId"
            id="userId"
            className={`form-control ${isEmployeeSelectedError ? 'error' : (selectedValue ? 'valid' : '')}`}
            value={selectedValue}
            onChange={(e) => handleChange(e.target.value)}
          >
            <option value="" disabled>Select an Employee</option>
            {employees.map((employee) => (
              <option key={employee.id} value={employee.id}>
                {employee.firstName}
              </option>
            ))}
          </select>
          {isEmployeeSelectedError && (
        <div className="text-danger mt-2">
          Please select an employee
        </div>
      )}
        </div>

          <div className="col-lg-3">
              <button
                className="btn btn-save text-white"
                onClick={() => ReAssignLead()}
                >
               <MdRepeat /> Re-Assign Leads
              </button>                
          </div>
  <div>

  </div>
</div>
)} 



      <div className='form-group row'>
        <div className='col-lg-9'>
        <p>Showing {filteredLeadsAssigned.length} entries</p>
        </div>
        </div>

      <div>
        <label htmlFor="leadStatus">Filter by Lead Status: </label>
        <select id="leadStatus" className='form-control' value={selectedStatus} onChange={handleStatusChange} >
          <option value="">All</option>
          {LeadStatus.map(status => (
            <option key={status.id} value={status.id}>
              {status.name}
            </option>
          ))}
        </select>
      </div>

      {filteredLeadsAssigned.length === 0 ? (
        <p>No leads found for the selected criteria.</p>
      ) : (
        <table className="table display" style={{ width: '100%' }}>
          <thead>
            <tr>
            
              <th>Select</th>
              <th>Lead Code</th>
              <th>Lead Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              {/* <th>Source Type</th> */}
              <th>Lead Status</th>      
              <th>Conversation Comments</th>
              <th>Property Type</th>
              <th>Property</th>
              <th>Lead Assigned Date</th>              
              <th>Employee</th>             
              <th>Follow Up</th>
              <th>Next FollowUp</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
       {renderLeadsAssigned()}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default FilterDataDashboardList;
