import axios from 'axios';
import AuthService from './AuthService';
import { Navigate } from 'react-router-dom';


const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  if (AuthService.isTokenExpired(token)) {
    AuthService.logout(); 
    <Navigate to="/login" /> 
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
 


const PrivacyPolicyService = {
  getAllPolicies: async () => {
    try {
      const response = await axios.get(`${API_URL}/PrivacyPolicy/GetAllPrivacyPolicy`);
      return response.data.$values;
    } catch (error) { 
      console.error('Error fetching policies:', error);
      throw error;
    }
  },

  getPolicyById: async (id) => { 
    try {
      const response = await axios.get(`${API_URL}/PrivacyPolicy/GetPrivacyPolicyById/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching policy with ID ${id}:`, error);
      throw error;
    } 
  },


  createPolicy: async (policy) => {
    try {
      const response = await axios.post(`${API_URL}/PrivacyPolicy/CreatePrivacyPolicy`, policy);
      return response.data;
    } catch (error) {
      console.error('Error creating Lead:', error);
      throw error;
    }
  },

  updatePolicy: async (id, policy) => {
    try {
      const response = await axios.put(`${API_URL}/PrivacyPolicy/UpdatePrivacyPolicy/${id}`, policy);
      return response.data;
    } catch (error) {
      console.error(`Error updating Policy with ID ${id}:`, error);
      throw error;
    }
  }, 

//   deleteLead: async (id) => {
//     try {
//       const response = await axios.delete(`${API_URL}/Lead/${id}`);
//       return response.data;
//     } catch (error) {
//       console.error(`Error deleting lead with ID ${id}:`, error); 
//       throw error;
//     }
//   },


};


export default PrivacyPolicyService;
