import axios from 'axios';
import AuthService from './AuthService';
import { Navigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  if (AuthService.isTokenExpired(token)) {
    AuthService.logout();
    <Navigate to="/login" /> 
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});


const NotificationService = {
  getnotifications: async (userId) => {
    try {
    
      const response = await axios.get(`${API_URL}/LeadAssignedNotification/GetLeadAssignedNotificationsByUserId/${userId}`);
      if (response.data && response.data.$values) {
        return response.data.$values;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
      throw error;
    }
  },
  getNotificationsByDateAsync: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/LeadAssignedNotification/GetNotificationsByDate/${userId}`);
      if (response.data && response.data.$values) {
        return response.data.$values;
      } else {
        return [];
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
   clearLeadAssignedNotificationById : async (id) => {
    try {
      const response = await axios.put(`${API_URL}/LeadAssignedNotification/ClearLeadAssignedNotificationById/${id}`);
      return response.data;
    } catch (error) {
      if (error.response.status === 404) {
        throw new Error(error.response.data);
      } else {
        throw error;
      }
    }
  },
  getNotificationsByDate: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/LeadAssignedNotification/GetLeadAssignedNotificationsByDate/${userId}`);
        const data = response.data;
        return {
          totalCount: data.totalCount,
          leadAssignedNotifications: data.leadAssignedNotifications
        };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },

};


export default NotificationService;