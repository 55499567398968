import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardService from "../../services/DashboardService";
import AuthService from "../../services/AuthService";
import CallsLineGraph from "./CallsLineGraph";
import axios from "axios";
import PieChart from "./PieChart";
import WelcomePopup from "./WelcomePopup";
import EmployeeWelcomePopup from "./EmployeeWelcomePopup";
import { FaEye, FaUser } from 'react-icons/fa';
import RegisterUserService from "../../services/RegisterUserService";
import LeadService from "../../services/LeadService";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Dashboard() {
  const [isLoggedIn, setIsLoggedIn] = useState(AuthService.isAuthenticated());
  const navigate = useNavigate();
  const [leadCount, setLeadCount] = useState(0);
  const [leadAssignedCount, setLeadAssignedCount] = useState(0);
  const [interestedCount, setInterestedCount] = useState(0);
  const [upcomingVisitCount, setUpcomingVisitCount] = useState(0);
  const [phoneNotPickedCount, setPhoneNotPickedCount] = useState(0);
  const [invalidNumberCount, setInvalidNumberCount] = useState(0);
  const [contactAfterSomeTimeCount, setContactAfterSomeTimeCount] = useState(0);
  const [callBackLaterCount, setCallBackLaterCount] = useState(0);
  const [totalCallsCount, setTotalCallsCount] = useState(0);
  const [visitedCount, setVisitedCount] = useState(0);
  const [hotClientCount, setHotClientCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const queryParam = useQuery();
  const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);
  const userRole = AuthService.getLoggedInRoleId(); 

  const currentUserId = AuthService.getCurrentUser().UserId;

  const [selectedEmployee, setSelectedEmployee] = useState(currentUserId);

  const [employees, setEmployees] = useState([]);

  const [showPopup, setShowPopup] = useState(false);
 
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const formattedStartOfMonth = startOfMonth.toISOString().split('T')[0];

  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);
  const formattedyesterday = yesterday.toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(formattedCurrentDate);
  const [endDate, setEndDate] = useState(formattedCurrentDate);

  const tenDaysAgo = new Date();
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
  const formattedTenDaysAgo = tenDaysAgo.toISOString().split('T')[0];

  const [leadStatus, setLeadStatus]= useState([]);
  
  useEffect(() => {
    const hasShownPopup = localStorage.getItem('hasShownPopup');
    if (!hasShownPopup) {
        setShowPopup(true);
        localStorage.setItem('hasShownPopup', 'true');
    }
}, []);

const handleClosePopup = () => {
    setShowPopup(false);
};


useEffect(() => {    

  const fetchLeadStatus =async()=>{
      try{
        const leadstatus = await LeadService.getAllLeadStatus();
        setLeadStatus(leadstatus);

      }catch(error){
        console.error('Error fetching lead status:', error);
      }
  }

  fetchLeadStatus();

}, [UserId]);

useEffect(() => {
  async function fetchEmployees() {
    try {
      const users = await RegisterUserService.getEmployessByRoleId(4);
      setEmployees(users);
      if (users.length > 0) {
        setSelectedEmployee(currentUserId); 
      }
      
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  }
  fetchEmployees();
}, [currentUserId]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      axios.defaults.headers.common["Authorization"] =  `Bearer ${token}`;
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    handleGetDashboardCards();
  }, [UserId, startDate, endDate]);
  

 async function handleGetDashboardCards() {
    setLoading(true);
    try {
      let userId;
      if (selectedEmployee == currentUserId) {
        userId = UserId;
      } else {
        userId = selectedEmployee;
      }  
  
      const response = await DashboardService.getDashboardCards(selectedEmployee, startDate, endDate);
      setLeadCount(response.leadCount || 0);
      setInterestedCount(response.interestedCount || 0);
      setUpcomingVisitCount(response.upcomingVisitCount || 0);
      setPhoneNotPickedCount(response.phoneNotPickedCount || 0);
      setInvalidNumberCount(response.invalidNumberCount || 0);
      setContactAfterSomeTimeCount(response.contactAfterSomeTimeCount || 0);
      setCallBackLaterCount(response.callBackLaterCount || 0);
      setTotalCallsCount(response.totalCallsCount || 0);
      setLeadAssignedCount(response.leadAssignedCount || 0);
      setVisitedCount(response.visitedCount || 0);
      setHotClientCount(response.hotClientCount || 0);

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
 
  const handleDateChange = (event) => {
    const value = event.target.value;
    const today = formattedCurrentDate;
    const currentYear = new Date().getFullYear();
   
    if (value === 'today') {
      setStartDate(today);
      setEndDate(today);
    } else if (value === 'yesterday') {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const formattedYesterday = yesterday.toISOString().split('T')[0];
      setStartDate(formattedYesterday);
      setEndDate(formattedYesterday);
    } else if (value === 'last10days') {
      setStartDate(formattedTenDaysAgo);
      setEndDate(today);
    } else if (value === 'thisMonth') {
      setStartDate(formattedStartOfMonth);
      setEndDate(today);
    } else if (value === 'lastMonth') {
      const lastMonthDate = new Date();
      lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
      lastMonthDate.setDate(1);
      const formattedStartOfLastMonth = lastMonthDate.toISOString().split('T')[0];
      
      lastMonthDate.setMonth(lastMonthDate.getMonth() + 1);
      lastMonthDate.setDate(0);
      const formattedEndOfLastMonth = lastMonthDate.toISOString().split('T')[0];
  
      setStartDate(formattedStartOfLastMonth);
      setEndDate(formattedEndOfLastMonth);
    } else if (value === 'thisYear') {
      const startOfYear = `${currentYear}-01-01`;
      setStartDate(startOfYear);
      setEndDate(today);
    } else if (value === 'lastYear') {
      const lastYear = currentYear - 1;
      const startOfLastYear = `${lastYear}-01-01`;
      const endOfLastYear = `${lastYear}-12-31`;
      setStartDate(startOfLastYear);
      setEndDate(endOfLastYear);
    }
  };

  const Role = {
    SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };

  return (
    <div>
     {userRole== Role.EMPLOYEE.roleId  && (
      <EmployeeWelcomePopup visible={showPopup} onClose={handleClosePopup} />
     )}

      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
       <WelcomePopup visible={showPopup} onClose={handleClosePopup} />
      )}


      <div className="form-group row">

      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

          <div className="d-flex col-md-4">
              <label className="col-4">
                Select Employee
              </label>
              <select
                className="col-7 form-control"
                value={selectedEmployee || ''}
                onChange={(e) => {
                  setSelectedEmployee(e.target.value);
                  handleGetDashboardCards();
                }}
              >
                <option value={currentUserId}>Current User</option>
                {employees.map(employee => (
                  <option key={employee.id} value={employee.id}>
                    {employee.firstName} {employee.lastName}
                  </option>
                ))}
              </select>
          </div>
    )}

</div>

      <div className="form-group row">
        <div className="d-flex row">

          <div className="d-flex col-md-3">
            <label className="col-4">Select:</label>
            <select
              className="col-sm-7 form-control"
              onChange={handleDateChange}
              defaultValue="today"
            >
              <option value="">Select</option>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="last10days">Last 10 Days</option>
              <option value="thisMonth">This Month</option>
              <option value="lastMonth">Last Month</option>
              <option value="thisYear">This Year</option>
              <option value="lastYear">Last Year</option>
            </select>
          </div>

          <div className="d-flex col-3">
            <label className="col-sm-4"> From: </label>
            <input className='col-sm-7 form-control' type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          </div>
          <div className="d-flex col-3">
            <label className="col-sm-4"> To: </label>
            <input className='col-sm-7 form-control' type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </div>
          <div className="col-2">
            <button className="btn btn-save text-white" onClick={handleGetDashboardCards}>Go</button>
          </div>
        </div>
      </div>


      {loading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <div class="">
            <div class="row m-2 p-2">

            {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

              <div className="col-2">
              <div className="card bg-c-blue order-card large-card" style={{ position: 'relative' }}>
                <div className="d-flex col-sm-12 align-items-center justify-content-center">                 
                    <FaUser className="mr-2" size={30} />
                    <h5 className="m-b-20">Leads: {leadCount}</h5>
                </div>
                <div 
                    onClick={() => navigate(`/filteredLeads/${selectedEmployee}/${startDate}/${endDate}`)}
                    style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'white', fontSize: '15px', cursor: 'pointer' }}
                  >
                    <FaEye className="mr-1" />View
                  </div>                          
              </div>
              </div>

            )}

            <div className="col-2">
              <div className="card bg-c-yellow order-card large-card" style={{ position: 'relative' }}>
                <div className="d-flex col-sm-12 align-items-center justify-content-center">
                    <FaUser className="mr-2" size={30} />
                <h5 class="m-b-20">Assigned Leads: {leadAssignedCount}</h5>
                </div>
                <div 
                 onClick={() => navigate(`/filteredDashboarddata/${selectedEmployee}/${startDate}/${endDate}`)} 
                  style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'white', fontSize: '15px', cursor: 'pointer' }}
                >
                  <FaEye className="mr-1" />View
                </div>    
              </div>
            </div>

            <div className="col-2">
              <div className="card bg-c-green order-card large-card" style={{ position: 'relative' }}>
                <div className="d-flex col-sm-12 align-items-center justify-content-center">
                    <FaUser className="mr-2" size={30} />
                       <h5 class="m-b-20">Interested: {interestedCount}</h5>
              </div>
              <div 
                 onClick={() => navigate(`/filteredDataEmployee/${selectedEmployee}/${startDate}/${endDate}/2`)} 
                  style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'white', fontSize: '15px', cursor: 'pointer' }}
                >
                  <FaEye className="mr-1" />View
                </div>   
              </div>
            </div>

            <div className="col-2">
              <div className="card bg-c-pink order-card large-card" style={{ position: 'relative' }}>
                <div className="d-flex col-sm-12 align-items-center justify-content-center">
                      <FaUser className="mr-2" size={30} />
                      <h5 class="m-b-20">Hot Client: {hotClientCount}</h5>
                </div>
                <div 
                  onClick={() => navigate(`/filteredDataEmployee/${selectedEmployee}/${startDate}/${endDate}/1`)} 
                  style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'white', fontSize: '15px', cursor: 'pointer' }}
                >
                  <FaEye className="mr-1" />View
                </div>   
              </div>
            </div>


             <div className="col-2">
              <div className="card bg-c-blue order-card large-card" style={{ position: 'relative' }}>
                <div className="d-flex col-sm-12 align-items-center justify-content-center">
                      <FaUser className="mr-2" size={30} />
                      <h5 class="m-b-20">Upcoming Visit: {upcomingVisitCount}</h5>
                </div>
                <div 
                  onClick={() => navigate(`/filteredDataEmployee/${selectedEmployee}/${startDate}/${endDate}/8`)} 
                  style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'white', fontSize: '15px', cursor: 'pointer' }}
                >
                  <FaEye className="mr-1" />View
                </div>   
              </div>
            </div>

            <div className="col-2">
              <div className="card bg-c-yellow order-card large-card" style={{ position: 'relative' }}>
                <div className="d-flex col-sm-12 align-items-center justify-content-center">
                      <FaUser className="mr-2" size={30} />  
                      <h5 class="m-b-20">Visited: {visitedCount}</h5>
                </div>
                <div 
                  onClick={() => navigate(`/filteredDataEmployee/${selectedEmployee}/${startDate}/${endDate}/10`)} 
                  style={{ position: 'absolute', bottom: '10px', right: '10px', color: 'white', fontSize: '15px', cursor: 'pointer' }}
                >
                  <FaEye className="mr-1" />View
                </div>   
              </div>
            </div>

            </div>

            


           <div className="row md-5 m-2 p-2">
                <div class="col-md-6 ">
                  <div class="">
                    <div class="">
                      <div>
                        <CallsLineGraph
                          totalCallsCount={totalCallsCount}
                          phoneNotPickedCount={phoneNotPickedCount}
                          invalidNumberCount={invalidNumberCount}
                          contactAfterSomeTimeCount={contactAfterSomeTimeCount}
                          callBackLaterCount={callBackLaterCount}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-1 ">
              </div>

              <div class="col-md-5">
                  <div class="">
                    <div class="">
                      <div>
                          <PieChart
                            leadCount={leadCount}
                            leadAssignedCount={leadAssignedCount}
                            upcomingVisitCount={upcomingVisitCount}
                            interestedCount={interestedCount}
                            totalCallsCount={totalCallsCount}
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </div> 

           
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;