import axios from 'axios';
import { Navigate } from 'react-router-dom';
import AuthService from '../../Admin/services/AuthService';


const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("accessToken");
  if (AuthService.isTokenExpired(token)) {
    AuthService.logout(); 
    <Navigate to="/login" /> 
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
 


const ClientService = {
  getAllCLients: async () => {
    try {
      const response = await axios.get(`${API_URL}/Client/GetAllClients`);
      return response.data.$values;
    } catch (error) { 
      console.error('Error fetching Clients:', error);
      throw error;
    }
  },

  getClientById: async (id) => { 
    try {
      const response = await axios.get(`${API_URL}/Client/GetClientByid/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching Client with ID ${id}:`, error);
      throw error;
    } 
  },


  createClient: async (clientdata) => {
    try {
      const response = await axios.post(`${API_URL}/Client/CreateClient`, clientdata);
      return response.data;
    } catch (error) {
      console.error('Error creating Client:', error);
      throw error;
    }
  },

  updateClient: async (id, clientdata) => {
    try {
      const response = await axios.put(`${API_URL}/Client/UpdateClient/${id}`, clientdata);
      return response.data;
    } catch (error) {
      console.error(`Error updating Client with ID ${id}:`, error);
      throw error;
    }
  }, 

  deleteClient: async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/Client/DeleteClient/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting Client with ID ${id}:`, error); 
      throw error;
    }
  },


};


export default ClientService;
