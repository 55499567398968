import React from 'react';

const PrintLead = ({ leadassigned }) => {
  const lead = leadassigned.lead;
  const sourceTypes = [
    { id: 1, name: "Facebook" },
    { id: 2, name: "Instagram" },
    { id: 3, name: "Reference" },
  ];
  const LeadStatus = [
    { id: 1, name: "HotClient" },
    { id: 2, name: "Interested" },
    { id: 3, name: "NotInterested" },
    { id: 4, name: "PhoneNotPicked" },
    { id: 5, name: "InvalidNumber" },
    { id: 6, name: "ContactAfterSomeTime" },
    { id: 7, name: "CallBackLater" },
    { id: 8, name: "UpcomingVisit" },
    { id: 9, name: "Complete" },
    { id: 10, name: "Visited" },
  ];
  const PropertyType = [
    { id: 1, name: "1BHK" },
    { id: 2, name: "2BHK" },
    { id: 3, name: "3BHK" },
    { id: 4, name: "4BHK" },
    { id: 5, name: "5BHK" },
    { id: 6, name: "Plot" },
    { id: 7, name: "Kothi" },
    { id: 8, name: "Other" },
  ];

  const sourceType = lead ? sourceTypes.find((source) => source.id === lead.sourceTypeId) : null;
  const sourceTypeName = sourceType ? sourceType.name : "Unknown";
  const leadStatus = LeadStatus.find((leadstatus) => leadstatus.id === leadassigned.leadStatusId);
  const leadStatusName = leadStatus ? leadStatus.name : "Unknown";
  const property = PropertyType.find((propertytype) => propertytype.id === leadassigned.propertyTypeId);
  const propertyTypeName = property ? property.name : "Unknown";

  return (
    <html>
      <head>
        <title>Print Lead</title>
        <style>
          {`
            body { font-family: Arial, sans-serif; padding: 20px; }
            table { width: 100%; border-collapse: collapse; }
            th, td { border: 1px solid #ccc; padding: 8px; text-align: left; }
            th { background-color: #f2f2f2; }
          `}
        </style>
      </head>
      <body>
        <h2>Lead Details</h2>
        <table>
          <tr><th>Lead Name</th><td>{lead ? `${lead.firstName}` : 'Unknown Lead'}</td></tr>
          <tr><th>Email</th><td>{lead ? lead.email : 'Unknown Lead'}</td></tr>
          <tr><th>Contact Number</th><td>{lead ? lead.contactNumber : 'Unknown Lead'}</td></tr>
          <tr><th>Lead Start Date</th><td>{lead ? new Date(lead.startDate).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: true,
            }) : 'Unknown Lead'}</td></tr>
          <tr><th>Lead Assigned Date</th><td>{new Date(leadassigned.leadAssignedDate).toLocaleString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: true,
            })}</td></tr>
          <tr><th>Source Type</th><td>{sourceTypeName}</td></tr>
          <tr><th>Lead Status</th><td>{leadStatusName}</td></tr>
          <tr><th>Property Type</th><td>{propertyTypeName}</td></tr>
          <tr><th>Conversation Comments</th><td>{leadassigned.conversationComment}</td></tr>
        </table>
        <br />
        <button onClick={() => window.print()}>Print</button>
      </body>
    </html>
  );
};

export default PrintLead;
