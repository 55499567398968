import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css'; 
import LeadService from '../../services/LeadService';
import AuthService from '../../services/AuthService';
import { MdAddCircleOutline } from 'react-icons/md';
import ToastService from '../../services/ToastService';
import { property } from 'lodash';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
  
function AddLead() {
  const queryParam = useQuery();
  const navigate = useNavigate();
  const toast = useRef(null);
  const [leads, setLeads] = useState([]);
  const toastService = new ToastService(toast); 
  const [LeadId] = useState(null);
  const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);

  const [form, setForm] = useState({
    firstname: "",
    lastname: "", 
    email: "",
    contactnumber: "",
    startDate:"",
    endDate:"",
    address:"",
    source:"",
    property:"",
    sourceTypeId:"",
    userId:UserId,
  });

  const [formError, setFormError] = useState({
    firstname: "",
    lastname: "",
    email: "",
    contactnumber: "",
    startDate:"",
    endDate:"",
    address:"",
    source:"",
    property:"",
    sourceTypeId:"",
    userId:UserId,
  });


  const SourceType = {
    FACEBOOK: { sourceTypeId: 1, sourceTypeName: 'Facebook' },
    INSTAGRAM: { sourceTypeId: 2, sourceTypeName: 'Instagram' },
    REFERENCE: { sourceTypeId: 1, sourceTypeName: 'Reference' },
  };
  

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    setFormError((prevErrors) => ({ ...prevErrors, [name]: '' })); 
  };
  

  async function getAll() {
    try {
      const leadsData = await LeadService.getAllLeads();
      setLeads(leadsData);
    } catch (error) {
      console.log('Error fetching leads: ', error);
    }
  }

  useEffect(() => {
    getAll();
  }, [LeadId]);

  function onLeadSubmit() {

    const errors = {
      firstname: form.firstname.trim().length === 0,
      email: form.email.trim().length === 0,
      contactnumber: form.contactnumber.trim().length === 0,
      startDate: form.startDate.trim().length === 0,
      endDate: form.endDate.trim().length === 0,
      address: form.address.trim().length === 0,
      source: form.source.trim().length === 0,
      property: form.property.trim().length === 0,
      sourceTypeId: form.sourceTypeId.trim().length === 0,
    };
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (form.email.trim().length > 0 && !emailRegex.test(form.email)) {
      errors.email = 'Please enter a valid email address';
    }
  
    const errorMessages = Object.keys(errors).reduce((acc, key) => {
      if (errors[key] === true) {
        return {
          ...acc,
          [key]: `Please enter ${key}!!!`,
        };
      } else if (typeof errors[key] === 'string') {
        return {
          ...acc,
          [key]: errors[key],
        };
      }
      return acc;
    }, {});
  

    setFormError(errorMessages);
  
    if (Object.keys(errorMessages).length === 0) {
     saveLead();
    }
  }
  

  function saveLead() {   
      const requestData = {
        firstname: form.firstname,
        lastname: form.lastname,
        email: form.email,
        contactnumber: form.contactnumber,
        startDate:form.startDate,
        endDate:form.endDate,
        sourceTypeId:form.sourceTypeId,
        address:form.address,
        source:form.source,
        property:form.property,
        UserId: UserId,
      };
      
      try {
        const response = LeadService.createLead(requestData);        
        resetForm();
        navigate("/leads")
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const validationErrors = error.response.data.errors;
          console.log("Validation errors:", validationErrors);
          toastService.showError("Validation Error", "Please fill out all required fields");
        } else {
          console.log("Error saving lead:", error);
          toastService.showError("Error", "Error saving lead");
        }
      }
    }

  function resetForm() {
    setForm({
      firstname: "",
      lastname: "",
      email: "",
      contactnumber: "",
      startDate:"",
      endDate:"",
      address:"",
      sourceTypeId: "",
      userId:UserId,
    });
    setFormError({
        firstname: "",
        lastname: "",
        email: "",
        contactnumber: "",
        startDate:"",
        endDate:"",
        address:"", 
        sourceTypeId: "",
        userId:UserId,
    });
  }

  return (
    <div>
      <Toast ref={toast} />

      <div className="row m-2 p-2">
            <div className="card text-center mx-auto">
                <div className="card-header bg-heading text-white">
                <MdAddCircleOutline />  New Lead
                </div>

                <div className="card-body text-start">

                    <div className="form-group row">
                        <label className="col-lg-2 text-dark" htmlFor="txtfirstName">
                             First Name
                        </label>
                        <div className="col-lg-4">
                            <input 
                              type="text" 
                              name="firstname" 
                              id="txtfirstName" 
                              className={`form-control ${formError.firstname ? 'is-invalid' : ''}`}
                              placeholder="First Name" 
                              onChange={changeHandler} 
                              value={form.firstname} 
                              />
                            {formError.firstname && <div className="invalid-feedback">{formError.firstname}</div>}   
                            <p></p> 
                        </div>
                        <label className="col-lg-2 text-dark" htmlFor="txtlastName">
                             Last Name
                        </label>
                        <div className="col-lg-4">
                            <input 
                              type="text" 
                              name="lastname" 
                              id="txtlastName" 
                              className={`form-control ${formError.lastname ? 'is-invalid' : ''}`}
                              placeholder="Last Name" 
                              onChange={changeHandler} 
                              value={form.lastname} 
                              />
                        </div>
                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="txtemail">
                            Email
                        </label>
                        <div className="col-lg-4">
                            <input type="email" name="email" id="txtemail" 
                            className={`form-control ${formError.email ? 'is-invalid' : ''}`}
                            placeholder="Email" onChange={changeHandler}
                            value={form.email} 
                            />
                          {formError.email && <div className="invalid-feedback">{formError.email}</div>}   
                          <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="contactNumber">
                           Contact Number
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="number" 
                            name="contactnumber" 
                            id="contactNumber"
                            className={`form-control ${formError.contactnumber ? 'is-invalid' : ''}`}
                            placeholder="Contact Number" 
                            onChange={(e) => {
                              if (e.target.value.length <= 13) {
                                  changeHandler(e);
                              }
                          }}
                          value={form.contactnumber} 
                          maxLength="13"
                            />
                           {formError.contactnumber && <div className="invalid-feedback">{formError.contactnumber}</div>}   
                           <p></p>
                        </div>
                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="startDate">
                          Start Date
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="datetime-local"
                            name="startDate" 
                            id="startDate"
                            className={`form-control ${formError.startDate ? 'is-invalid' : ''}`}
                            onChange={changeHandler}
                            value={form.startDate} 
                            />
                           {formError.startDate && <div className="invalid-feedback">{formError.startDate}</div>}   
                           <p></p>
                        </div>

                        <label className="col-lg-2 text-dark" htmlFor="endDate">
                          End Date
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="datetime-local"
                            name="endDate" 
                            id="endDate"
                            className={`form-control ${formError.endDate ? 'is-invalid' : ''}`}
                            onChange={changeHandler}
                            value={form.endDate} 
                            />
                           {formError.endDate && <div className="invalid-feedback">{formError.endDate}</div>}   
                           <p></p>
                        </div>

                    </div>

                    <div className="form-group row">

                    <label className="col-lg-2 text-dark" htmlFor="address">
                             Address
                        </label>
                        <div className="col-lg-4">
                            <input 
                              type="text" 
                              name="address" 
                              id="address" 
                              className={`form-control ${formError.address ? 'is-invalid' : ''}`}
                              placeholder="Address" 
                              onChange={changeHandler} 
                              value={form.address} 
                              />
                            {formError.address && <div className="invalid-feedback">{formError.address}</div>}   
                            <p></p> 
                        </div>

                    <label className="col-lg-2 text-dark" htmlFor="sourceTypeId">
                        Source Type
                    </label>
                    <div className="col-lg-4">
                    <select
                    name="sourceTypeId"
                    id="sourceTypeId"
                    className={`form-control ${formError.sourceTypeId ? 'is-invalid' : ''}`}
                    value={form.sourceTypeId}
                    onChange={changeHandler}
                    >
                    <option value="">Select Source Type</option>
                    <option value={SourceType.FACEBOOK.sourceTypeId}>{SourceType.FACEBOOK.sourceTypeName}</option>
                    <option value={SourceType.INSTAGRAM.sourceTypeId}>{SourceType.INSTAGRAM.sourceTypeName}</option>
                    <option value={SourceType.REFERENCE.sourceTypeId}>{SourceType.REFERENCE.sourceTypeName}</option>
                    
                    </select>
                    {formError.sourceTypeId && (
                    <div className="invalid-feedback">{formError.sourceTypeId}</div>
                    )}
                    </div>       
                               
                    </div>

                <div className="form-group row">

                  <label className="col-lg-2 text-dark" htmlFor="property">
                     Property
                      </label>
                      <div className="col-lg-4">
                          <input 
                            type="text" 
                            name="property" 
                            id="property" 
                            className={`form-control ${formError.property ? 'is-invalid' : ''}`}
                            placeholder="Property"   
                            onChange={changeHandler} 
                            value={form.property} 
                            />
                          {formError.property && <div className="invalid-feedback">{formError.property}</div>}   
                          <p></p> 
                      </div>

                      <label className="col-lg-2 text-dark" htmlFor="source">
                          Source
                        </label>
                        <div className="col-lg-4">
                            <input 
                              type="text" 
                              name="source" 
                              id="source" 
                              className={`form-control ${formError.source ? 'is-invalid' : ''}`}
                              placeholder="Source" 
                              onChange={changeHandler} 
                              value={form.source} 
                              />
                              {formError.source && <div className="invalid-feedback">{formError.source}</div>}   
                              <p></p> 
                        </div>
            
                  </div>

                  </div>
                
                <div className="card-footer text-muted bg-light">
                    <button className="btn btn-save btn-dark text-white" type="submit" onClick={onLeadSubmit}>
                    <i className="fas fa-save"></i> Submit
                    </button>
                </div>
            </div>
        </div>

    </div>
  );
}

export default AddLead;
