import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css'; 
import LeadsAssignedService from '../../services/LeadsAssignedService';
import AuthService from '../../services/AuthService';
import LeadService from '../../services/LeadService';
import ToastService from '../../services/ToastService';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
 
function AddLeadsAssigned() {
    const queryParam = useQuery();
    const navigate = useNavigate();
    const toast = useRef(null);
    const toastService = new ToastService(toast); 
    const [leadsAssigned, setLeadsAssigned] = useState([]);
    const [leadsAssignedId] = useState([]);
    const [leads, setLeads] = useState([]);
    const [selectedLeadId, setSelectedLeadId] = useState(queryParam.get('leadId'));
    const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);
  
    const [form, setForm] = useState({
      leadId: selectedLeadId,
      leadStatusId: "",
      propertyTypeId: "",
      userId: UserId, 
      conversationcomment:"",
      leadassigneddate:"",
      followupdate:"",
      nextupdate:"",
    });
  
    const [formError, setFormError] = useState({}); 
  
    const LeadStatus = {
      HOTCLIENT: { leadStatusId: 1, leadStatusName: 'HotClient' },
      INTERESTED: { leadStatusId: 2, leadStatusName: 'Interested' },
      NOTINTERESTED: { leadStatusId: 3, leadStatusName: 'NotInterested' },
      PHONENOTPICKED: { leadStatusId: 4, leadStatusName: 'PhoneNotPicked' },
      INVALIDNUMBER: { leadStatusId: 5, leadStatusName: 'InvalidNumber' },
      CONTACTAFTERSOMETIME: { leadStatusId: 6, leadStatusName: 'ContactAfterSomeTime' },
      CALLBACKLATER: { leadStatusId: 7, leadStatusName: 'CallBackLater' },
      UPCOMINGVISIT: { leadStatusId: 8, leadStatusName: 'UpcomingVisit' },
      COMPLETE: { leadStatusId: 9, leadStatusName: 'Complete' },
      VISITED: { leadStatusId: 10, leadStatusName: 'Visited' }
    };
  
    const PropertyType = {
      ONEBHK: { propertyTypeId: 1, propertyTypeName: '1BHK' },
      TWOBHK: { propertyTypeId: 2, propertyTypeName: '2BHK' },
      THREEBHK: { propertyTypeId: 3, propertyTypeName: '3BHK' },
      FOURBHK: { propertyTypeId: 4, propertyTypeName: '4BHK' },
      FIVEBHK: { propertyTypeId: 5, propertyTypeName: '5BHK' },
      PLOT: { propertyTypeId: 6, propertyTypeName: 'Plot' },
      KOTHI: { propertyTypeId: 7, propertyTypeName: 'Kothi' },
    };
    
  
    const changeHandler = (e) => {
      const { name, value } = e.target;
      setForm({ ...form, [name]: value });
      setFormError((prevErrors) => ({ ...prevErrors, [name]: '' })); 
    };
    
  
    async function getAll() {
      try {
        const leadsAssignedData = await LeadsAssignedService.getAllLeadsAssigned();
        setLeadsAssigned(leadsAssignedData);
      } catch (error) {
        console.log('Error fetching leads: ', error);
      }
    }
  
    useEffect(() => {
      getAll();
  }, [leadsAssignedId]);

  useEffect(() => {
    async function fetchLeads() {
      try {
        const leads = await LeadService.getLeadsByUserId(AuthService.getLoggedInUserId());
        setLeads(leads);
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    }
   
    fetchLeads(); 
  }, []);

  function onLeadAssignedSubmit() {
    const errors = {
      conversationcomment: form.conversationcomment.trim().length === 0,
      leadassigneddate: form.leadassigneddate.trim().length === 0,
      followupdate: form.followupdate.trim().length === 0,
      nextupdate: form.nextupdate.trim().length === 0,
    };

    const error = Object.keys(errors).reduce((acc, key) => {
      if (errors[key]) {
        return {
          ...acc, 
          [key]: `Please enter ${key}!!!`,
        };
      }
      return acc;
    }, {}); 

    setFormError(error);
    if (Object.keys(error).length === 0) {
       saveLeadAssigned()
    }
  }

  function saveLeadAssigned() {   

    if (!selectedLeadId) {
        console.error('No lead selected');
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Please select a lead',
          life: 3000,
        }); 
        return;
      }

      const requestData = {
        leadId: selectedLeadId,
        leadassigneddate: form.leadassigneddate,
        conversationcomment: form.conversationcomment,
        followupdate: form.followupdate,
        nextupdate: form.nextupdate,
        leadStatusId: form.leadStatusId,
        propertyTypeId:form.propertyTypeId,
        UserId: UserId,
      };
      
      try {        
        const response = LeadsAssignedService.createLeadAssigned(requestData);
        toastService.showSuccess("Success", "Lead created successfully!");

        resetForm();
        navigate("/leadassigned")
      } catch (error) {
        if (error.response && error.response.status === 400) {
          const validationErrors = error.response.data.errors;
          console.log("Validation errors:", validationErrors);
          toastService.showError("Validation Error", "Validation");
        } else {
          console.log("Error saving lead:", error);
          toastService.showError("Error", "An error occurred while saving the lead.");
        }
      }
  }

  function resetForm() {
    setForm({
        leadId: "",
        leadStatusId: "",
        propertyTypeId: "",
        userId: "", 
        conversationcomment:"",
        leadassigneddate:"",
    });
    setFormError({
        leadId: "",
        leadStatusId: "",
        propertyTypeId: "",
        userId: "", 
        conversationcomment:"",
        leadassigneddate:"",
    });
  }

  return (
    <div>
      <Toast ref={toast} />
      <div className="row m-2 p-2">
            <div className="card text-center mx-auto">
                <div className="card-header bg-heading text-white">
                    New Lead Assigned
                </div>

                <div className="card-body text-start">
 
                  <div className="form-group row">

                  <label className="col-lg-2 text-dark" htmlFor="leadId">
                    Select Lead
                  </label>

                  <div className="col-lg-4">
                    <select
                      name="userId"
                      id="userId"
                      className={`form-control`}
                      value={selectedLeadId}
                      onChange={(e) => setSelectedLeadId(e.target.value)}
                    >
                      <option value="">Select Lead</option>
                      {leads.map((lead) => (
                        <option key={lead.id} value={lead.id}>
                          {lead.firstName}
                        </option>
                      ))}
                    </select>
                    <p>{formError.leadId && (
                      <div className="invalid-feedback">{formError.leadId}</div>
                    )}</p>
                  </div>
                  </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="leadassigneddate">
                          Lead Assigned Date
                        </label>
                        <div className="col-lg-4">
                            <input 
                            type="datetime-local"
                            name="leadassigneddate" 
                            id="leadassigneddate"
                            className={`form-control ${formError.leadAssignedDate ? 'is-invalid' : ''}`}
                            placeholder="Lead Assigned Date" onChange={changeHandler}
                            value={form.leadassigneddate} 
                            />
                           {formError.leadassigneddate && <div className="invalid-feedback">{formError.leadassigneddate}</div>}   
                           <p></p>
                        </div>

                        <label className="col-lg-2 text-dark" htmlFor="conversationcomment">
                          Conversation Comments
                        </label>
                        <div className="col-lg-4">
                            <textarea 
                            type="text" 
                            rows="3"
                            name="conversationcomment" 
                            id="conversationcomment"
                            className={`form-control ${formError.conversationcomment ? 'is-invalid' : ''}`}
                            placeholder="Conversation Comments"
                            onChange={changeHandler}
                            value={form.conversationcomment} 
                            />
                           {formError.conversationcomment && <div className="invalid-feedback">{formError.conversationcomment}</div>}   
                           <p></p>
                        </div>

                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="followupdate">
                          Follow Up
                        </label>
                        <div className="col-lg-4">
                            <input 
                             type="datetime-local"
                            name="followupdate" 
                            id="followupdate"
                            className={`form-control ${formError.followupdate ? 'is-invalid' : ''}`}
                            placeholder="Follow Up date" onChange={changeHandler}
                            value={form.followupdate} 
                            />
                           {formError.followupdate && <div className="invalid-feedback">{formError.followupdate}</div>}   
                           <p></p>
                        </div>

                        <label className="col-lg-2 text-dark" htmlFor="nextupdate">
                        Next Follow Up 
                        </label>
                        <div className="col-lg-4">
                        <input 
                            type="datetime-local"
                            name="nextupdate" 
                            id="nextupdate"
                            className={`form-control ${formError.nextupdate ? 'is-invalid' : ''}`}
                            placeholder="Next FollowUp date" onChange={changeHandler}
                            value={form.nextupdate} 
                            />
                           {formError.nextupdate && <div className="invalid-feedback">{formError.nextupdate}</div>}   
                           <p></p>
                        </div>

                    </div>

                    <div className="form-group row">
                    <label className="col-lg-2 text-dark" htmlFor="leadStatusId">
                        Lead Status
                    </label>
                    <div className="col-lg-4">
                    <select
                    name="leadStatusId"
                    id="leadStatusId"
                    className={`form-control ${formError.leadStatusId ? 'is-invalid' : ''}`}
                    value={form.leadStatusId}
                    onChange={changeHandler}
                    >
                    <option value="">Select Lead Status</option>
                    <option value={LeadStatus.HOTCLIENT.leadStatusId}>{LeadStatus.HOTCLIENT.leadStatusName}</option>
                    <option value={LeadStatus.INTERESTED.leadStatusId}>{LeadStatus.INTERESTED.leadStatusName}</option>
                    <option value={LeadStatus.NOTINTERESTED.leadStatusId}>{LeadStatus.NOTINTERESTED.leadStatusName}</option>
                    <option value={LeadStatus.PHONENOTPICKED.leadStatusId}>{LeadStatus.PHONENOTPICKED.leadStatusName}</option>
                    <option value={LeadStatus.INVALIDNUMBER.leadStatusId}>{LeadStatus.INVALIDNUMBER.leadStatusName}</option>
                    <option value={LeadStatus.CONTACTAFTERSOMETIME.leadStatusId}>{LeadStatus.CONTACTAFTERSOMETIME.leadStatusName}</option>
                    <option value={LeadStatus.CALLBACKLATER.leadStatusId}>{LeadStatus.CALLBACKLATER.leadStatusName}</option>
                    <option value={LeadStatus.UPCOMINGVISIT.leadStatusId}>{LeadStatus.UPCOMINGVISIT.leadStatusName}</option>
                    <option value={LeadStatus.COMPLETE.leadStatusId}>{LeadStatus.COMPLETE.leadStatusName}</option>
                    <option value={LeadStatus.VISITED.leadStatusId}>{LeadStatus.VISITED.leadStatusName}</option>
                    </select>
                    {formError.leadStatusId && (
                    <div className="invalid-feedback">{formError.leadStatusId}</div>
                    )}
                    </div>
                  
                    <label className="col-lg-2 text-dark" htmlFor="propertyTypeId">
                        Property Type 
                    </label>
                    <div className="col-lg-4">
                    <select
                    name="propertyTypeId"
                    id="propertyTypeId"
                    className={`form-control ${formError.propertyTypeId ? 'is-invalid' : ''}`}
                    value={form.propertyTypeId}
                    onChange={changeHandler}
                    >
                    <option value="">Select Property Type</option>
                    <option value={PropertyType.ONEBHK.propertyTypeId}>{PropertyType.ONEBHK.propertyTypeName}</option>
                    <option value={PropertyType.TWOBHK.propertyTypeId}>{PropertyType.TWOBHK.propertyTypeName}</option>
                    <option value={PropertyType.THREEBHK.propertyTypeId}>{PropertyType.THREEBHK.propertyTypeName}</option>
                    <option value={PropertyType.FOURBHK.propertyTypeId}>{PropertyType.FOURBHK.propertyTypeName}</option>
                    <option value={PropertyType.FIVEBHK.propertyTypeId}>{PropertyType.FIVEBHK.propertyTypeName}</option>
                    <option value={PropertyType.PLOT.propertyTypeId}>{PropertyType.PLOT.propertyTypeName}</option>
                    <option value={PropertyType.KOTHI.propertyTypeId}>{PropertyType.KOTHI.propertyTypeName}</option>
             
                    </select>
                    {formError.propertyTypeId && (
                    <div className="invalid-feedback">{formError.propertyTypeId}</div>
                    )}
                    </div>
                  
                    </div>

                </div>
                
                <div className="card-footer text-muted bg-light">
                   
                    <button className="btn btn-save btn-dark text-white" type="submit" onClick={onLeadAssignedSubmit}>
                        Submit
                    </button>
                  
                </div>
            </div>
        </div>

    </div>
  );
}

export default AddLeadsAssigned;
