import React, { useState, useRef, useEffect } from 'react';
import PrivacyPolicyService from '../../modules/Admin/services/PrivacyPolicyService';
import ToastService from '../../modules/Admin/services/ToastService';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import TermsAndConditionsService from '../../modules/Admin/services/TermsAndConditionsService';
import AuthService from '../../modules/Admin/services/AuthService';

function TermsAndConditions() {
    const [Terms, setTerms] = useState([]);
    const [termId, setTermId] = useState(null);
    const toast = useRef(null);
    const toastService = new ToastService(toast); 
    const navigate = useNavigate();
    const userRole = AuthService.getLoggedInRoleId(); 

    const [form, setForm] = useState({
        termsandconditionstext: "",
        createddate: "", 
      });

    const [formError, setFormError] = useState([]);

    async function fetchTerms() {
        try {
          const terms = await TermsAndConditionsService.getAllTerms();
          setTerms(terms);
        } catch (error) {
          console.error('Error fetching terms and conditions:', error);
          toastService.showError("Error", "Failed to fetching terms and conditions");
        }
      };

      useEffect(() => {
        fetchTerms();
      });


      function saveTerm() {   
        const requestData = {
        termsandconditionstext: form.termsandconditionstext,
        createddate: form.createddate, 
        };
    
        try {
           TermsAndConditionsService.createTerms(requestData);        
           toastService.showSuccess('Success', 'Terms and conditions created successfully');
          resetForm();
        } catch (error) {
          if (error.response && error.response.status === 400) {
            toastService.showError("Validation Error", "Please fill out all required fields");
          } else {
            toastService.showError("Error", "Error saving Terms and Conditions");
          }
        }
      }

   async function updateTerm() {
    
    const formData=new FormData();
    formData.append("termsandconditionstext",form.termsandconditionstext);
    formData.append("createddate",form.createddate);
    formData.append("id",termId);


        try {
          await TermsAndConditionsService.updateTerms(termId, formData); 
          resetForm();
          toastService.showSuccess('Success', 'Terms and conditions updated successfully');
        } catch (error) {
          console.error("Error updating Terms and conditions:", error);
          toastService.showError('Error', 'Failed to update Terms and conditions');
        }
      }; 


      function resetForm() {
        setForm({
            termsandconditionstext: "",
            createddate: "", 
        });
        setFormError({
            termsandconditionstext: "",
            createddate: "", 
        });
      }


      const Role = {
        SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
        ADMIN: { roleId: 2, roleName: 'Admin' },
        MANAGER: { roleId: 3, roleName: 'Manager' },
        EMPLOYEE: { roleId: 4, roleName: 'Employee' },
      };

      function onTermSubmit(){
          termId ? updateTerm() : saveTerm();
      }


      const handleInputChange = (e) => {
        setForm({
          ...form,
          [e.target.name]: e.target.value,
        });
      };
    
      const renderTermsInParagraph = () => {
        return Terms.map((t) => (
          <p key={t.id}>
            <span>{t.termsAndConditionsText}</span><br />
            <p><small>{new Date(t.createdDate).toLocaleString()}</small>  

            {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (
            <button className="btn "
                    onClick={()=>{
                        setForm({...form, termsandconditionstext:t.termsAndConditionsText, createddate:t.createdDate});
                        setTermId(t.id);
                    }}>
              <i className="pi pi-pencil" style={{ fontSize: '0.8rem' }}></i>
            </button>

            )}
            </p><br/>
           

          </p>
        ));
      };

  return (
    <div>
    <Toast ref={toast} />

         <div className="container mt-4 bg-light">
              <div className="row">
                  {/* <div className="col-md-8 offset-md-2"> */}
                  <div className="col-12">
                      <div className="text-center mb-4">
                          <h2>Terms and Conditions</h2>
                      </div>
                    
                    <h5>1. General Acceptance</h5>
                        <p> By accessing and using our website or any services provided by Dream Town Builder and Promoters, 
                              you accept and agree to be bound by these Terms and Conditions. 
                              If you do not agree with any of these terms, you should refrain from using our services.</p>
                            <p></p>

                            <h5> 2. Service Description</h5>
                        <p> Dream Town Builder and Promoters offer property development,
                           sales, and real estate promotion services. We strive to ensure 
                           that the information provided on our website is accurate and up-to-date; 
                           however, we do not warrant the completeness or accuracy of the content.</p>
                            <p></p>

                            <h5>3. Use of the Website</h5>
                        <p>You agree to use the website only for lawful purposes and in a way that does not infringe the rights of, 
                          restrict, or inhibit anyone else's use of the site. Prohibited behavior includes harassing or causing distress to any person, 
                          transmitting obscene or offensive content, or disrupting the normal flow of dialogue within the site.</p>
                            <p></p>

                            <h5>4. Property Listings and Information</h5>
                        <p>All property listings on our website are subject to change without prior notice. 
                          Dream Town Builder and Promoters reserve the right to modify, update, or remove listings at our discretion. 
                          Property availability and prices mentioned on the site may vary,
                           and all transactions are subject to final verification and agreement.</p>
                            <p></p>

                            <h5>5. No Warranty</h5>
                        <p> We make every effort to ensure that the information on our website is correct, but we do not guarantee the accuracy, 
                          reliability, or completeness of any content, including third-party content. All services are provided “as is,”
                           without any warranty, either expressed or implied, including 
                          but not limited to the implied warranties of merchantability and fitness for a particular purpose.</p>
                            <p></p>


                            <h5>6. Limitation of Liability</h5>
                        <p> Dream Town Builder and Promoters shall not be held liable for any indirect, incidental, or 
                          consequential damages arising from your use of our website or services.
                           This includes but is not limited to loss of profits, data, or business opportunities.</p>
                            <p></p>


                            <h5>7. Privacy Policy</h5>
                        <p> Your use of the website is also governed by our Privacy Policy, 
                          which explains how we collect, use, 
                          and protect your personal information.</p>
                            <p></p>


                            <h5>8. Intellectual Property Rights</h5>
                        <p> All content on this website, including but not limited to text, images, logos, and design, 
                          is the property of Dream Town Builder and Promoters unless otherwise stated. 
                          Unauthorized use of any content may result in legal action.</p>
                            <p></p>


                            <h5>9. Third-Party Links  </h5>
                        <p> Our website may contain links to third-party websites. These links are provided for your convenience only. 
                          Dream Town Builder and Promoters do not endorse or assume any responsibility for the content, 
                          privacy policies, or practices of these third-party websites.</p>
                            <p></p>


                            <h5>10. Amendments</h5>
                        <p> We reserve the right to update or modify these Terms and Conditions at any time without prior notice. 
                          It is your responsibility to review these terms periodically for any changes. 
                          Continued use of the site following any changes constitutes acceptance of the revised terms.</p>
                            <p></p>


                            <h5>11. Governing Law</h5>
                        <p> These Terms and Conditions are governed by and construed in accordance with the laws of India.
                           Any disputes relating to these terms shall be subject to the exclusive jurisdiction of the courts of Chandigarh.</p>
                            <p></p>


                            <h5>12. Contact Information</h5>
                        <p> If you have any questions or concerns about these Terms and Conditions, please contact us: </p>
                          <p>Email: <a href="mailto:info.dreamtownbuilders@gmail.com" target="_blank" rel="noopener noreferrer" style={{ color: "#007BFF" }}> 
                          info.dreamtownbuilders@gmail.com</a>. </p>
                            <p></p>

                  </div>
              </div>
         </div>

       <br/>

      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

          <div className='form-group row'>

              <h5>{termId?"Edit Terms and Conditions":"New Terms and Conditions"}</h5> 

                <label className="col-1 text-dark col-form-label">Terms & use</label>
                <div className="col-lg-3">
                <textarea
                rows={3}
                className="form-control"
                name="termsandconditionstext"
                value={form.termsandconditionstext}
                onChange={handleInputChange}
                placeholder="Enter Terms and conditions..." 
              />
              </div>

              <label className="col-1 text-dark col-form-label">Created Date</label>
              <div className="col-2">
              <input
                type="datetime-local"
                name="createddate"
                value={form.createddate}
                onChange={handleInputChange}
                placeholder="Date and time"
              /> 
              </div>

                <div className="col-1">
                {termId?(
                <button onClick={()=>{
                  onTermSubmit();
                }}
                className='btn btn-info'>
                  Update
                </button>
              ):(
                <button onClick={()=>{
                  onTermSubmit();
                }} 
                className="btn btn-save btn-dark text-white">
                  <i className="fas fa-save"></i>
                  Save
                </button>
              )
            }
                </div>

              {/* <div className='col-lg-4'> */}
              <p>{renderTermsInParagraph()}</p>
              {/* </div> */}

          </div>
      )}      

    </div>
  );
};

export default TermsAndConditions;
