import { NavLink } from "react-router-dom";
import { FaBars, FaFilter, FaHome, FaUserAlt, FaUserAltSlash } from "react-icons/fa";
import { MdAddBox, MdAddCircleOutline, MdInfo,MdOutlineSecurity,MdPrivacyTip } from "react-icons/md";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

const routes = [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <FaHome />,
    },
    {
      path: "/leadassigned",
      name: "Assigned Leads",
      icon: <MdAddBox />,
    }, 
    
    {
      path: "/pendingfollowups",
      name: "Pending FollowUps",
      icon: <FaUserAlt />,
    },

    
    {
      path: "/quicksupport",
      name: "Quick Support",
      icon: <FaPhoneSquareAlt  />,
    },
    {
      path: "/about",
      name: "About",
      icon: <MdInfo />,
    },
    {
      path: "/privacy",
      name: "Privacy",
      icon: <MdPrivacyTip />,
    },
    {
      path: "/termsandconditions",
      name: "Terms and Conditions",
      icon: <MdOutlineSecurity />,
    },
  
   
  ];



const SideBar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const inputAnimation = {
      hidden: {
        width: 0,
        padding: 0,
        transition: {
          duration: 0.2,
        },
      },
      show: {
        width: "140px",
        padding: "5px 15px",
        transition: {
          duration: 0.2,
        },
      },
    };
  
    const showAnimation = {
      hidden: {
        width: 0,
        opacity: 0,
        transition: {
          duration: 0.5,
        },
      },
      show: {
        opacity: 1,
        width: "auto",
        transition: {
          duration: 0.5,
        },
      },
    };


return (
    <>
      <div className="main-container">
        <motion.div
          animate={{
            width: isOpen ? "200px" : "45px",
       
            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >  
          <div className="top_section">
            <AnimatePresence>
              {isOpen && (
                <motion.h1
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="logo text-white"
                >
                  DreamTown
                </motion.h1>
              )}
            </AnimatePresence>
            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>

          <section className="routes">
          {routes.map((route, index) => {
            return (
              <div key={index}>
                <NavLink
                  to={route.path}
                  className="link"
                  activeClassName="active"
                >
                  <div className="icon">{route.icon}</div>
                  {isOpen && (
                    <div className="link_text">{route.name}</div>
                  )}
                </NavLink>
                {isOpen && route.submenu && (
                  <div className="submenu">
                    {route.submenu.map((submenuItem, subIndex) => (
                      <NavLink
                        key={subIndex}
                        to={submenuItem.path}
                        className="submenu_link"
                        activeClassName="active"
                      >
                        <div className="icon">{submenuItem.icon}</div>
                        <div className="link_text">{submenuItem.name}</div>
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </section>

        </motion.div>

         <main>
             {children}
         </main>
         
      </div>

    </>
  );
};

export default SideBar;
