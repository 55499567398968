import axios from 'axios';
import AuthService from './AuthService';
import { Navigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(function (config) {

  const token = localStorage.getItem("accessToken");
  if (AuthService.isTokenExpired(token)) {
    AuthService.logout(); 
    <Navigate to="/login" /> 
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  } 
  return config;
});
 


const LeadsAssignedService = {
  getAllLeadsAssigned: async (userId) => {
    try {
      const response = await axios.get(`${API_URL}/LeadAssigned/GetAllLeadAssigned/${userId}`);
      return response.data.$values;
    } catch (error) {
      console.error('Error fetching leads assigned: ', error);
      throw error;
    }
  },

  getAllLeadsAssignedByDate: async (userId, leadAssignedDate) => {
    try {
      const response = await axios.get(`${API_URL}/LeadAssigned/GetLeadAssignedByDate/${userId}/${leadAssignedDate}`);
      return response.data.$values;
    } catch (error) {
      console.error('Error fetching leads assigned by Date: ', error);
      throw error;
    }
  },

  getAllLeadsAssignedByDateAsync: async (userId, startDate, endDate) => {
    try {
      const response = await axios.get(`${API_URL}/LeadAssigned/GetLeadAssignedByDateAsync/${userId}/${startDate}/${endDate}`);
      return response.data.$values;
    } catch (error) {
      console.error('Error fetching leads assigned by Date: ', error);
      throw error;
    }
  },

  getLeadAssignedByLeadStatusId: async (leadStatusId, userId) => {
    try {
      const response = await axios.get(`${API_URL}/LeadAssigned/GetLeadAssignedByLeadStatusId/${leadStatusId}/${userId}`);
      return response.data.$values;
    } catch (error) {
      console.error('Error fetching leads assigned by lead status: ', error);
      throw error;
    }
  },

  getLeadsAssignedById: async (id) => { 
    try {
      const response = await axios.get(`${API_URL}/LeadAssigned/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error fetching lead assigned with ID ${id}:`, error);
      throw error;
    } 
  },

  getLeadAssignedId:async()=>{
  const response=await axios.get(`${API_URL}/LeadAssigned`);
  return response.data.id;
  },

  createLeadAssigned: async (leadData) => {
    try {
      const response = await axios.post(`${API_URL}/LeadAssigned`, leadData);
      return response.data;
    } catch (error) {
      console.error('Error creating Lead assigned:', error);
      throw error;
    }
  },

  updateLeadAssigned: async (id, leadData) => {
    try {
      const response = await axios.put(`${API_URL}/LeadAssigned/${id}`, leadData);
      return response.data;
    } catch (error) {
      console.error(`Error updating leads assigned with ID ${id}:`, error);
      throw error;
    }
  }, 

  deleteLeadAssigned: async (id) => {
    try {
      const response = await axios.delete(`${API_URL}/LeadAssigned/${id}`);
      return response.data;
    } catch (error) {
      console.error(`Error deleting leads assigned with ID ${id}:`, error); 
      throw error;
    }
  },

   searchLeadsAssignedByName : async (query) => {
    try {
      const response = await axios.get(`${API_URL}/LeadAssigned/search`, {
        params: { name: query }  
      });
      return response.data;  
    } catch (error) {
      console.error('Error searching leads assigned:', error);
      throw error;
    }
  },

ReAssignedLeads: async (userId, leadIds) => {
  try {
    const requestBody = {
      leadIds,
      leadAssignedDtos: [
        {
          leadStatusId: 8,
          propertyTypeId: 2,
          conversationComment: 'Assigned to employee',
          leadAssignedDate: new Date().toISOString(),
          userId,
        },
      ],
    };
    const response = await axios.put(`${API_URL}/LeadAssigned/UpdateAssignedLeadsToEmployee/${userId}/${leadIds}`, requestBody);
    return response.data;
  } catch (error) {
    console.error('Error creating Lead:', error);
    throw error;
  }
},


getPendingFollowups: async(userId) => {
  try {
    const response = await axios.get(`${API_URL}/LeadAssigned/GetPendingFollowupList/${userId}`);
    return response.data.$values;
  } catch (error) {
    console.error(error);
    throw error;
  }
},

};


export default LeadsAssignedService;
