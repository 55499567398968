import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ToastService from '../../../Admin/services/ToastService';
import ClientService from '../../services/ClientService';
import $ from 'jquery';
import 'datatables.net';
import { Toast } from 'primereact/toast';



function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Clients() {

  const query = useQuery();
  const tableRef = useRef(null);
  const [Clients, setClients] = useState([]);
  const navigate = useNavigate();
  const toast = useRef(null);
  const toastService = new ToastService(toast); 
  

  async function fetchClients () {
    try {
      const clientsData = await ClientService.getAllCLients();
      setClients(clientsData);
    } catch (error) {
      console.error('Error fetching Clients:', error);
      toastService.showError("Error", "Failed to fetch clients data");
    }
  };


  useEffect(() => {
    fetchClients();
  
    setTimeout(() => {
      $('#tableData').DataTable();
    }, 1000);
  });


  const renderClients = () => {
    return Clients.map((client) => {
      return (
        <tr key={client.id}>
          <td>{client.clientName}</td>
          <td>{client.streetAddress}</td>
          <td>{client.city}</td>
          <td>{client.state}</td>
          <td>{client.country}</td>
          <td>{client.postalCode}</td>

          <td>
            <button
              className="btn btn-outline-dark btn-sm"
              onClick={() => navigate(`/editClient/${client.id}`)}
            >
              <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
            </button>

          </td>
        </tr>
      );
    });
  };

    
  return (
    <div>
      <Toast ref={toast} />

      <h2>Clients</h2>

      <div className='form-group row'>
        <div>
          <button
            className="btn btn-save offset-11 text-white"
            onClick={() => navigate("/addClient")}
          >
            <i className="pi pi-plus"></i>
            Add
          </button>
        </div>
      </div> 

      <div className="p-2 m-2">
        <table id='tableData' ref={tableRef} className="table display" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Client Name</th>
              <th>Street Address</th>
              <th>City</th>
              <th>State</th>
              <th>Country</th>
              <th>Postal Code</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {renderClients()}
          </tbody>
        </table>
      </div>

    </div>
  )
}

export default Clients