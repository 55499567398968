import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const PieChart = ({ leadCount, leadAssignedCount, visitorCount, interestedCount, totalCallsCount }) => {
  const data = {
    labels: ['Leads', 'Assigned Leads', 'Upcoming Visits', 'Interested Clients', 'Total Calls'],
    datasets: [
      {
        label: 'Data', 
        data: [leadCount, leadAssignedCount, visitorCount, interestedCount, totalCallsCount],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false, 
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Lead and Call Statistics',
        font: {
          size: 18
        },
        padding: {
          top: 10,
          bottom: 30
        },
      },
    },
  };


  return (
    <div style={{ width: '450px', height: '450px' }}>
      <Pie data={data} options={options} />
    </div>
  );
};

export default PieChart;
