import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import LeadService from '../../services/LeadService';
import $ from 'jquery';
import 'datatables.net';
import RegisterUserService from '../../services/RegisterUserService';
import AuthService from '../../services/AuthService';
import { FaFileImport, FaRedoAlt, FaUserAlt } from 'react-icons/fa';
import ToastService from '../../services/ToastService';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
 
function Leads() {
  const [selectedRows, setSelectedRows] = useState(new Set());
  const [employees, setselectedUser] = useState([]);
  const queryParam = useQuery();
  const [selectedValue, setSelectedValue] = useState('');
  const [UserId] = useState(queryParam.get("id") || AuthService.getCurrentUser().UserId);
  const tableRef = useRef(null);
  const [leads, setLeads] = useState([]);
  const navigate = useNavigate();
  const [isEmployeeSelectedError, setIsEmployeeSelectedError] = useState(false);

  const toast = useRef(null);
  const toastService = new ToastService(toast); 
  const userRole = AuthService.getLoggedInRoleId();

const currentDate = new Date();
const formattedCurrentDate = currentDate.toISOString().split('T')[0];
const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const formattedStartOfMonth = startOfMonth.toISOString().split('T')[0];

const [startDate, setStartDate] = useState(formattedCurrentDate);
const [endDate, setEndDate] = useState(formattedCurrentDate);

const tenDaysAgo = new Date();
tenDaysAgo.setDate(tenDaysAgo.getDate() - 10);
const formattedTenDaysAgo = tenDaysAgo.toISOString().split('T')[0];


  async function fetchLeads () {
  try {
    const leadsData = await LeadService.getAllLeads(UserId);
    //to sort data in descndng order 
    // const sortedLeads = leadsData.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));
    // setLeads(sortedLeads);
    setLeads(leadsData);
  } catch (error) {
    console.error('Error fetching leads:', error);
    toastService.showError("Error", "Failed to fetch leads data");
  }
};


async function fetchEmployee () {
  try { 
    const users = await RegisterUserService.getEmployessByRoleId(4);
    setselectedUser(users);
  } catch (error) {
    console.error('Error fetching employees:', error);
    toastService.showError("Error", "Failed to fetch employees data");
  }
};

useEffect(() => {
  fetchLeads(startDate, endDate);
  fetchEmployee();

  setTimeout(() => {
    $('#tableData').DataTable();
  }, 1000);
}, [UserId]);

  const handleDateChange = (event) => {
    const value = event.target.value;
    const today = formattedCurrentDate;

    if (value === 'today') {
      setStartDate(today);
      setEndDate(today);
    } else if (value === 'yesterday') {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const formattedYesterday = yesterday.toISOString().split('T')[0];
      setStartDate(formattedYesterday);
      setEndDate(formattedYesterday);
    } else if (value === 'last10days') {
      setStartDate(formattedTenDaysAgo);
      setEndDate(today);
    } else if (value === 'thisMonth') {
      setStartDate(formattedStartOfMonth);
      setEndDate(today);
    }
  };

  const handleChange = (event) => {
    setSelectedValue(event);
    if (event) {
      setIsEmployeeSelectedError(false);
    }
  };

  const handleCheckboxChange = (leadId) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = new Set(prevSelectedRows);
      if (updatedSelectedRows.has(leadId)) {
        updatedSelectedRows.delete(leadId);
      } else {
        updatedSelectedRows.add(leadId);
      }
      return updatedSelectedRows;
    });
  };

  const sourceTypes = [
    { id: 1, name: "Facebook" },
    { id: 2, name: "Instagram" },
    { id: 3, name: "Reference" },
  ];

 
  const AssignLead = async () => {
    setIsEmployeeSelectedError(!selectedValue);
  
    if (selectedRows.size === 0) {
      toastService.showError("Error", "Please select at least one lead");
      return;
    }
  
    if (!selectedValue) {
      toastService.showError("Validation Error", "Please select an employee.");
      return;
    }
  
    try {
      await LeadService.AssignedLeadsToEmployee(selectedValue, Array.from(selectedRows));
      toastService.showSuccess("Success", "Leads assigned successfully");
      
      setSelectedValue('');
      setSelectedRows(new Set());
      fetchLeads();
    } catch (error) {
      toastService.showError("Error", "Failed to assign leads");
    }
  };
  
  
  const Role = {
    SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };



  const renderLeads = () => {
    return leads.map((item) => {
      const sourceType = sourceTypes.find((source) => source.id === item.sourceTypeId);
      const sourceTypeName = sourceType ? sourceType.name : "Unknown";

      return (
        <tr key={item.id}>
          <td> 
            <input
              type="checkbox"
              className="row-checkbox"
              data-row={item.id}
              disabled={item.isLeadAssigned}
              checked={selectedRows.has(item.id)}
              onChange={() => handleCheckboxChange(item.id)}
            /> 
          </td>
          <td>{item.leadCode}</td>
          <td>{item.firstName}</td>
          <td>{item.email}</td>
          <td>{item.contactNumber}</td>
          <td>{item.address}</td>
          <td>{item.property}</td>
          <td>{item.source}</td>
          <td>
            {new Date(item.startDate).toLocaleDateString()}
          </td>

          <td>
            {new Date(item.endDate).toLocaleDateString()}
          </td>

          {/* <td className={getStatusClass(sourceTypeName)}>
            {sourceTypeName}
          </td> */}

          <td>
            <button
              className="btn btn-outline-dark btn-sm"
              onClick={() => navigate(`/editlead/${item.id}`)}
            >
              <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
            </button>

          </td>
        </tr>
      );
    });
  };

  function getStatusClass(sourceTypeName) {
    switch (sourceTypeName) {
      case 'Facebook':
        return 'table-warning'; 
      case 'Instagram':
        return 'table-danger'; 
      case 'Reference':
        return 'table-info';   
      default:
        return ''; 
    }
  }

  return (
    <div>
      <Toast ref={toast} />

      <h1>Leads</h1>

      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

      <div className='form-group row'>

        <div className='col-lg-6'>
          <div className="form-group row">           
            <div className="col-lg-6">
              <select
                name="userId"
                id="userId"
                className={`form-control ${isEmployeeSelectedError ? 'error' : (selectedValue ? 'valid' : '')}`}
                value={selectedValue}
                onChange={(e) => handleChange(e.target.value)}
              >
                <option value="" disabled>Select an employee to assign lead(s)</option>
                {employees.map(employee => (
                  <option key={employee.id} value={employee.id}>
                    {employee.firstName}
                  </option>
                ))}
              </select>
              {isEmployeeSelectedError && (
                <div className="text-danger mt-2">
                  Please select an employee
                </div>
              )}
            </div>

            <div className="col-lg-3">
              <button className="btn btn-save text-white" onClick={() => AssignLead()}>
              <FaUserAlt/>  Assign Leads
              </button>
            </div>
          </div>
        </div>
        
        <div className='col-lg-6 form-group row'>
          <div className="col-lg-4">
            <button
              className="btn btn-save text-white"
              onClick={() => navigate("/importLeads")}
            >
             <FaFileImport/> Import Leads
            </button>
          </div>
        </div>
      </div>
      )}

      <div className='form-group row'>
        <div>
          <button
            className="btn btn-save offset-11 text-white"
            onClick={() => navigate("/addlead")}
          >
            <i className="pi pi-plus"></i>
            Add
          </button>
        </div>
      </div> 

      <div className="p-2 m-2">
        <table id='tableData' ref={tableRef} className="table display" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Select</th>
              <th>Lead Code</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Address</th>
              <th>Property</th>
              <th>Source</th>
              <th>Start Date</th>
              <th>End Date</th>
              {/* <th>Source Type</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {renderLeads()}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Leads;
