import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import RegisterUserService from '../../services/RegisterUserService';
import $ from 'jquery';
import 'datatables.net';
import { MdAddCircleOutline } from 'react-icons/md';


function RegisterUser() {
  const [User, setUser] = useState([]);
  const toast = useRef(null);
  const tableRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {    
    const fetchUsers = async () => {
      try {
        const users = await RegisterUserService.getAllUsers();
        setUser(users);
      } catch (error) {
        console.error('Error fetching Users: ', error);  
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to fetch users data' });
      }
    };
    fetchUsers();
    setTimeout(() => {
      $('#tableData').DataTable();
    }, 1000);
  }, []);

  const Roles = [
    { id: 1, name: "SuperAdmin" },
    { id: 2, name: "Admin" },
    { id: 3, name: "Manager" },
    { id: 4, name: "Employee" }, 
  ];

//   function renderUsers() {
//     return User.map((user) => {
//       const roles = Roles.find((role) => role.id === user.roleId);
//       const roleName = roles ? roles.name : "Unknown";

//     return (
//       <tr key={user.id}>
//         <td>{user.firstName} {user.lastName}</td>
//         <td>{user.email}</td>
//         <td>{user.contactNumber}</td>
//         <td>{`${user.streetAddress}, ${user.city}, ${user.state}, ${user.country}, ${user.postalCode}`}</td>
//         <td>{roleName}</td>
//         <td>{user.isActive ? 'Active' : 'InActive'}</td> 
//         <td>
//           <button
//             className="btn btn-outline-dark btn-sm"
//             onClick={() => navigate(`/edituser/${user.id}`)}
//           >
//             <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
//           </button> 
//        <i></i>
//           <button
//             className="btn btn-outline-light btn-sm"
//             onClick={() => deleteUser(user.id)}
//           >
//            <i className="pi pi-trash" style={{ fontSize: '1.5rem', color: 'red' }}></i>
//           </button>
         
//         </td>
//         </tr>
//       );
//     });
// };


function renderUsers() {
  
  return User.filter((user) => {
    const roles = Roles.find((role) => role.id == user.roleId);
    return roles && roles.name !== "SuperAdmin"; 
  }).map((user) => {
    const roles = Roles.find((role) => role.id == user.roleId);
    const roleName = roles ? roles.name : "Unknown";

    return (
      <tr key={user.id}>
        <td>{user.firstName} {user.lastName}</td>
        <td>{user.email}</td>
        <td>{user.contactNumber}</td>
        <td>{`${user.streetAddress}, ${user.city}, ${user.state}, ${user.country}, ${user.postalCode}`}</td>
        <td>{roleName}</td>
        <td>{user.isActive ? 'Active' : 'Inactive'}</td>
        <td>
          <button
            className="btn btn-outline-dark btn-sm"
            onClick={() => navigate(`/edituser/${user.id}`)}
          >
            <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
          </button>
          <button
            className="btn btn-outline-light btn-sm"
            onClick={() => deleteUser(user.id)}
          >
            <i className="pi pi-trash" style={{ fontSize: '1.5rem', color: 'red' }}></i>
          </button>
        </td>
      </tr>
    );
  });
}

  const deleteUser = async (userId) => {
    try {
      await RegisterUserService.deleteUser(userId);
      toast.current.show({ severity: 'success', summary: 'Success', detail: 'User deleted successfully', life: 3000 });
      setUser(User.filter(user => user.id !== userId));
    } catch (e) {
      toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete user', life: 3000 });
      console.log(e);
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <h1>Employees</h1>
      <div>
        <button
          className="btn btn-save offset-10 text-white"
          onClick={() => {
            navigate("/adduser");
          }}
        >
          <MdAddCircleOutline /> Add New Employee
        </button>
      </div>

      <div className='p-2 m-2'>
      <table id='tableData'  ref={tableRef} className="table display">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Contact Number</th>
              <th>Address</th>
              <th>Role</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody> 
            {renderUsers()}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RegisterUser;
