import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import LeadsAssignedService from '../../services/LeadsAssignedService';
import { Toast } from 'primereact/toast';
import AuthService from '../../services/AuthService';
import LeadService from '../../services/LeadService';
import { FaFileImport, FaUserAlt } from 'react-icons/fa';
import ToastService from '../../services/ToastService';
import RegisterUserService from '../../services/RegisterUserService';
import $ from 'jquery';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function FilterLeadsDashboard() {
  const { employeeId, fromDateParam, toDateParam } = useParams();

  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const queryParam = useQuery();
  const fromDate = new Date(fromDateParam);
  const toDate = new Date(toDateParam);
  const [Leads, setLeads] = useState([]);
  const [userId, setUserId] = useState(AuthService.getCurrentUser().UserId);
  const [globalSearchTerm, setGlobalSearchTerm] = useState('');
  const [isEmployeeSelectedError, setIsEmployeeSelectedError] = useState(false);
  const userRole = AuthService.getLoggedInRoleId();
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedRows, setSelectedRows] = useState(new Set());
  const toast = useRef(null);
  const toastService = new ToastService(toast); 
  const tableRef = useRef(null);


  async function fetchLeads () {
    try {
      const leadsData = await LeadService.getAllLeads(userId);
      setLeads(leadsData);
    } catch (error) {
      console.error('Error fetching leads:', error);
      toastService.showError("Error", "Failed to fetch leads data");
    }
  };
  
  
  async function fetchEmployee () {
    try { 
      const users = await RegisterUserService.getEmployessByRoleId(4);
      setEmployees(users);
    } catch (error) {
      console.error('Error fetching employees:', error);
      toastService.showError("Error", "Failed to fetch employees data");
    }
  };

  useEffect(() => {
    fetchLeads();
    fetchEmployee();
  
    setTimeout(() => {
      $('#tableData').DataTable();
    }, 1000);
  }, [userId]);



  const handleChange = (event) => {
    setSelectedValue(event);
    if (event) {
      setIsEmployeeSelectedError(false);
    }
  };

  const handleCheckboxChange = (leadId) => {
    setSelectedRows((prevSelectedRows) => {
      const updatedSelectedRows = new Set(prevSelectedRows);
      if (updatedSelectedRows.has(leadId)) {
        updatedSelectedRows.delete(leadId);
      } else {
        updatedSelectedRows.add(leadId);
      }
      return updatedSelectedRows;
    });
  };
  

  const filteredLeads = Leads.filter((lead) => {

    const leadNameMatches = lead ? 
        `${lead.firstName}`.toLowerCase().includes(globalSearchTerm.toLowerCase()) : false;
    const phoneNumberMatches = lead ? 
        lead.contactNumber.toLowerCase().includes(globalSearchTerm.toLowerCase()) : false;
    const emailMatches = lead ? 
        lead.email.toLowerCase().includes(globalSearchTerm.toLowerCase()) : false;

    // const searchMatches = lead.conversationComment &&
    //     lead.conversationComment.toLowerCase().includes(globalSearchTerm.toLowerCase()) ||
    //     leadNameMatches || phoneNumberMatches || emailMatches;
    
    const clearTime = (date) => {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
      };
      const leadDate = clearTime(new Date(lead.startDate));
      const fromDateClear = fromDate ? clearTime(new Date(fromDate)) : null;
      const toDateClear = toDate ? clearTime(new Date(toDate)) : null;
      const isWithinDateRange =  (!fromDateClear || leadDate >= fromDateClear) &&  (!toDateClear || leadDate <= toDateClear);

    const isEmployeeMatch = !employeeId || userId == employeeId  || lead.userId == employeeId;

    return  isWithinDateRange && isEmployeeMatch;
  });

    
  const Role = {
    SUPERADMIN: { roleId: 1, roleName: 'SuperAdmin' },
    ADMIN: { roleId: 2, roleName: 'Admin' },
    MANAGER: { roleId: 3, roleName: 'Manager' },
    EMPLOYEE: { roleId: 4, roleName: 'Employee' },
  };


  const AssignLead = async () => {
    setIsEmployeeSelectedError(!selectedValue);
  
    if (selectedRows.size === 0) {
      toastService.showError("Error", "Please select at least one lead");
      return;
    }
  
    if (!selectedValue) {
      toastService.showError("Validation Error", "Please select an employee.");
      return;
    }
  
    try {
      await LeadService.AssignedLeadsToEmployee(selectedValue, Array.from(selectedRows));
      toastService.showSuccess("Success", "Leads assigned successfully");
      
      setSelectedValue('');
      setSelectedRows(new Set());
      fetchLeads();
    } catch (error) {
      toastService.showError("Error", "Failed to assign leads");
    }
  };
  

  
  return (
    <div>
      <Toast ref={toast} />
      <h3>Filtered Leads</h3>

      {(userRole== Role.SUPERADMIN.roleId || userRole == Role.ADMIN.roleId || userRole == Role.MANAGER.roleId) && (

        <div className='form-group row'>

          <div className='col-lg-6'>
            <div className="form-group row">
            
              <div className="col-lg-6">
                <select
                  name="userId"
                  id="userId"
                  className={`form-control ${isEmployeeSelectedError ? 'error' : (selectedValue ? 'valid' : '')}`}
                  value={selectedValue}
                  onChange={(e) => handleChange(e.target.value)}
                >
                  <option value="" disabled>Select an employee to assign lead(s)</option>
                  {employees.map(employee => (
                    <option key={employee.id} value={employee.id}>
                      {employee.firstName}
                    </option>
                  ))}
                </select>
                {isEmployeeSelectedError && (
                  <div className="text-danger mt-2">
                    Please select an employee
                  </div>
                )}
              </div>

              <div className="col-lg-3">
                <button className="btn btn-save text-white" onClick={() => AssignLead()}>
                <FaUserAlt/>  Assign Leads
                </button>
              </div>
            </div>
          </div>
          
          {/* <div className='col-lg-6 form-group row'>
            <div className="col-lg-4">
              <button
                className="btn btn-save text-white"
                onClick={() => navigate("/importLeads")}
              >
              <FaFileImport/> Import Leads
              </button>
            </div>
          </div> */}

        </div>

      )}

      <div className='form-group row'>
        <div className='col-lg-9'>
        <p>Showing {filteredLeads.length} entries</p>
        </div>
        </div>


      {filteredLeads.length === 0 ? (
        <p>No leads found for the selected criteria.</p>
      ) : (
        <table id='tableData' ref={tableRef} className="table display" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Select</th>
              <th>Lead Code</th>
              <th>Name</th>
              <th>Email</th>
              <th>Mobile</th>
              <th>Address</th>
              <th>Property</th>
              <th>Start Date</th>
              <th>End Date</th>
              {/* <th>Source Type</th> */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredLeads.map((lead) => (
              <tr key={lead.id}>
                <td> 
            <input
              type="checkbox"
              className="row-checkbox"
              data-row={lead.id}
              disabled={lead.isLeadAssigned}
              checked={selectedRows.has(lead.id)}
              onChange={() => handleCheckboxChange(lead.id)}
            /> 
          </td>
                <td>{lead.leadCode}</td>
                <td>{lead.firstName}</td>
                <td>{lead.email}</td>
                <td>{lead.contactNumber}</td>
                <td>{lead.address}</td>
                <td>{lead.property}</td>
                <td>
                    {new Date(lead.startDate).toLocaleDateString()}
                </td>

                <td>
                    {new Date(lead.endDate).toLocaleDateString()}
                </td>

                {/* <td className={getStatusClass(sourceTypeName)}>
                    {sourceTypeName}
                </td> */}

                <td>
                    <button
                    className="btn btn-outline-dark btn-sm"
                    onClick={() => navigate(`/editlead/${lead.id}`)}
                    >
                    <i className="pi pi-pencil" style={{ fontSize: '1.5rem' }}></i>
                    </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default FilterLeadsDashboard;
