import React, { useEffect, useRef, useState } from 'react'
import {useNavigate, useParams } from 'react-router-dom';
import ToastService from '../../../Admin/services/ToastService';
import ClientService from '../../services/ClientService';
import { Toast } from 'primereact/toast';


function EditClient() {

  const toast = useRef(null);
  const { id } = useParams();  
  const navigate = useNavigate();
  const toastService = new ToastService(toast); 

  const [form, setForm] = useState({
    clientName: "",
    streetAddress: "", 
    city: "",
    state: "",
    country:"",
    postalCode:"",
    id: id,
  });

  const [formError, setFormError] = useState({}); 

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const data = await ClientService.getClientById(id);
        setForm(data);
      } catch (error) {
        console.error('Error fetching Clients data:', error);
      } 
    };

    fetchClients();
  }, [id]);
  
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setForm(prevForm => ({ ...prevForm, [name]: value }));
  };
  

  const validateForm = () => {

    const errors = {};
    if (!form.clientName.trim()) errors.clientName = "Please enter the Client name.";
    if (!form.streetAddress.trim()) errors.streetAddress = "Please enter the street address.";
    if (!form.city.trim()) errors.city = "Please enter city.";
    if (!form.state) errors.state = "Please enter the state.";
    if (!form.country) errors.country = "Please enter the country.";
    if (!form.postalCode) errors.postalCode = "Please enter the postal code .";
    
    setFormError(errors);

    if (Object.keys(errors).length > 0) {
      toastService.showWarning('Validation Error', 'Please fill out all required fields');
    }

    return Object.keys(errors).length === 0;
  };

  const onClientSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    updateClient();
  };

  
  const updateClient = async () => {
    const formDataToSend = new FormData();
    Object.keys(form).forEach(key => {
          formDataToSend.append(key, form[key]);
        }
      );
    try {
        debugger
      await ClientService.updateClient(id, formDataToSend); 
      toastService.showSuccess('Success', 'Client updated successfully');
      setTimeout(() => {
      navigate(-1);
      }, 1000); 
    } catch (error) {
      console.error("Error updating Client:", error);
      toastService.showError('Error', 'Failed to update Client');
    }
  };


  return (
    <div>
         <Toast ref={toast} />
        <h2>Edit Client</h2>

        <form onSubmit={onClientSubmit}>
        <div className="form-group row">
        <label className="col-lg-2" htmlFor="clientname">
                        Name
                </label>
                <div className="col-lg-4">
                    <input 
                        type="text" 
                        name="clientname" 
                        id="clientname" 
                        className={`form-control ${formError.clientName ? 'is-invalid' : ''}`}
                        placeholder="Client Name" 
                        onChange={changeHandler} 
                        value={form.clientName} 
                        />
                    {formError.clientName && <div className="invalid-feedback">{formError.clientName}</div>}   
                    <p></p> 
                </div>

                <label className="col-lg-2" htmlFor="streetAddress">
                        Street Address
                </label>
                <div className="col-lg-4">
                    <input 
                        type="text" 
                        name="streetAddress" 
                        id="streetAddress" 
                        className={`form-control ${formError.streetAddress ? 'is-invalid' : ''}`}
                        placeholder="Street Address" 
                        onChange={changeHandler} 
                        value={form.streetAddress} 
                        />

                </div>

            </div>

      <div className="form-group row">
      <label className="col-lg-2" htmlFor="city">
              City
          </label>
          <div className="col-lg-4">
              <input type="text" name="city" id="city" 
              className={`form-control ${formError.city ? 'is-invalid' : ''}`}
              placeholder="City" onChange={changeHandler}
              value={form.city} 
              
              />
            {formError.city && <div className="invalid-feedback">{formError.city}</div>}   
            <p></p> 
          </div>

      <label className="col-lg-2" htmlFor="state">
                State
          </label>
          <div className="col-lg-4">
              <input 
              type="text" 
              name="state" 
              id="state"
              className={`form-control ${formError.state ? 'is-invalid' : ''}`}
              placeholder="State" onChange={changeHandler}
              value={form.state} 
              maxLength="13"
              />
              {formError.state && <div className="invalid-feedback">{formError.state}</div>}   
              <p></p>
          </div>
      </div>

        <div className="form-group row">

        <label className="col-lg-2" htmlFor="country">
              Country
          </label>
          <div className="col-lg-4">
              <input type="text" name="country" id="country" 
              className={`form-control ${formError.country ? 'is-invalid' : ''}`}
              placeholder="Country" onChange={changeHandler}
              value={form.country} 
              />
            {formError.country && <div className="invalid-feedback">{formError.country}</div>}   
            <p></p> 
          </div>

          <label className="col-lg-2" htmlFor="postalCode">
                    Postal Code
          </label>
          <div className="col-lg-4">
              <input 
                type="text" 
                name="postalCode" 
                id="postalCode" 
                className={`form-control ${formError.postalCode ? 'is-invalid' : ''}`}
                placeholder="Postal Code" 
                onChange={changeHandler} 
                value={form.postalCode} 
                />
                {formError.postalCode && <div className="invalid-feedback">{formError.postalCode}</div>}   
                <p></p> 
          </div>
          </div>                   
        
        <div className="col-md-12 text-center">
            <button type="submit" className="btn btn-save text-white btn-center">Update</button>
        </div>
        </form>

        </div>
  )
}

export default EditClient